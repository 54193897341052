import React, { useEffect } from "react";
import { useParams } from 'react-router-dom'
import { useState } from 'react';
import axios from 'axios';
import Header from "./header";
import './css/pregledrezervacija.css'
import Footer from "./footer";
import {Helmet} from "react-helmet"

function PregledRezervacija(props) {

    const [finalRooms,setFinalRooms] = useState([]);
    const [finalCijena,setFinalCijena] = useState([]);
    const [finalpaketIme,setfinalpaketIme] = useState("");
    const [dolazakDatum,setDolazakDatum] = useState("");
    const [odlazakDatum,setOdlazakDatum] = useState("");
    const [rezerviranoDatum,setRezerviranoDatum] = useState("");
    const [idRez,setIdRez] = useState("");
    const [napomena,setNapomena] = useState("");
    const [ime,setIme] = useState("")
    const [prezime,setPrezime] = useState("")
    const [email,setEmail] = useState("")
    const [drzava,setDrzava] = useState("")

    const [checkinlink,setcheckinlink] = useState("");
    const [check_in,setcheck_in] = useState("");


    
    
    const {id} = useParams();


    let content = {
        English: {
            labelDolazak : "Date of arrival",
            labelOdlazak : "Date of departure",
            brojOdraslih: "Number of adults (14+ years)",
            brojDjece: "Number of children (0-14 years)",
            paketSoba: " rooms",
            singlebed:"Single bed",
            bracniKrevet:"King size bed",
            ime:"Name:",
            prezime:"Surname:",
            država:"Country of residence",
            napomena:"Message to host:",
            sažetakRezervacije:"Reservation summary",
            cijena:"Price paid",      
            rezerviranoDatum:"Date when the reservation was made",
            idRez:"Id of a Reservation",
            gost:"Guest information",
            molimocheckin:"Will be set 2 days before arrival",
            vazno:"Important",
            pinvrata:"Pin for the door :",
            kliknimene:"Click me for check in",
            brojsobe:"Room name",
            pozicijasobe:"Room position",
            pozicijaopis1:"First floor to the left",
            pozicijaopis2:"First floor to the left",
            pozicijaopis3:"First floor to the right",
            pozicijaopis4:"Second floor to the left",
            pozicijaopis5:"Second floor to the left",
            pozicijaopis6:"Second floor to the right",
            confirmationemail:"CONFIRMATION MAIL IS SENT 24h AFTER RESERVATION"

        },
        German: {
            labelDolazak : "Datum der Ankunft",
            labelOdlazak : "Abreisedatum",
            brojOdraslih: "Anzahl Erwachsene (14+ Jahre)",
            brojDjece: "Anzahl Kinder (0-14 Jahre)",
            paketSoba: "Zimmerpaket",
            singlebed:"Bett für 1 Person",
            bracniKrevet:"Kingsize-Bett",
            ime:"Name::",
            prezime:"Nachname:",
            država:"Land des Wohnsitzes",
            napomena:"Anmerkung:",
            sažetakRezervacije:"Buchungsübersicht",
            cijena:"Preis bezahlt",      
            rezerviranoDatum:"Datum der Reservierung",
            idRez:"Reservierungs-ID",
            gost:"Gäste Information",
            molimocheckin:"Wird 2 Tage vor Ankunft festgelegt",
            vazno:"Wichtig",
            pinvrata:"Tür pin :",
            napravljeno:"Gelöst",
            kliknimene:"CHECK IN",
            brojsobe:"Die Name an der Zimmertür",
            pozicijasobe:"Raumposition",
            pozicijaopis1:"Erste Stockt",
            pozicijaopis2:"Erste Stockt",
            pozicijaopis3:"Erste Stockt",
            pozicijaopis4:"Zweiter Stock",
            pozicijaopis5:"Zweiter Stock",
            pozicijaopis6:"Zweiter Stock",
            confirmationemail:"BESTÄTIGUNGSMAIL WIRD 24 Stunden NACH DER RESERVIERUNG VERSENDET"

        },
        Croatian: {
            labelDolazak : "Datum dolaska",
            labelOdlazak : "Datum odlaska",
            brojOdraslih: "Broj odraslih (14+ godina)",
            brojDjece: "Broj djece (0-14 godina)",
            paketSoba: "paket soba",
            singlebed:"Krevet za 1 osobu",
            bracniKrevet:"King size krevet",
            ime:"Ime:",
            prezime:"Prezime:",
            država:"Država prebivališta",
            napomena:"Napomena:",
            sažetakRezervacije:"Sažetak rezervacije",
            cijena:"Plaćena cijena",      
            rezerviranoDatum:"Datum izrade rezervacije",
            idRez:"Id rezervacije",
            gost:"Informacije o gostu",
            molimocheckin:"bit će postavljeno 2 dana prije dolaska",
            vazno:"Važno",
            pinvrata:"Pin za vrata :",
            napravljeno:"Riješeno",
            kliknimene:"Klikni na mene za check in",
            brojsobe:"Ime na vratima sobe",
            pozicijasobe:"Pozicija sobe",
            pozicijaopis1:"Prvi kat na lijevo",
            pozicijaopis2:"Prvi kat na lijevo",
            pozicijaopis3:"Prvi kat na desno",
            pozicijaopis4:"Drugi kat na lijevo",
            pozicijaopis5:"Drugi kat na lijevo",
            pozicijaopis6:"Drugi kat na desno",
            confirmationemail:"EMAIL POTVRDA REZERVACIJE ĆE BITI POSLANA NAKON 24 SATA"


        }
    }

   if(props.language === "English") {
      content = content.English
   } else if(props.language === "Croatian") {
      content = content.Croatian
   } else {
       content = content.German
   }
        
    useEffect(() => {
        ucitajRezervaciju();
    },[]);
   

    function ucitajRezervaciju() {
        axios.get('/reservation?id='+id)
        .then((res) => res.data)
        .then(data => {
            setFinalCijena(data.cijena)
            setDolazakDatum(data.rezervacije[0].dolazak)
            setOdlazakDatum(data.rezervacije[0].odlazak)
            setfinalpaketIme(data.rezervacije[0].sobe.vrstaSobe)

            setRezerviranoDatum(data.rezervacije[0].rezerviranoDatuma)
            setIdRez(id)
            setNapomena(data.rezervacije[0].napomenaGosta)
            setIme(data.rezervacije[0].gost.ime)
            setPrezime(data.rezervacije[0].gost.prezime)
            setEmail(data.rezervacije[0].gost.email)
            setDrzava(data.rezervacije[0].gost.drzava)

            let sobe = [];

            for(let i = 0; i < data.rezervacije.length;i++) {
                if(data.rezervacije[i].pinZaVrata === 0) {
                    data.rezervacije[i].pinZaVrata = content.molimocheckin;
                } 
                if(data.rezervacije[i].checked_in === true) {
                    switch(data.rezervacije[i].sobe.sobaId) {
                        case 1136581:
                            data.rezervacije[i].brojsobe = 1;
                            break;
                        case 1136593:
                            data.rezervacije[i].brojsobe = 2;
                            break;
                        case 1136596:
                            data.rezervacije[i].brojsobe = 3;
                            break;
                        case 1136590:
                            data.rezervacije[i].brojsobe = 4;
                            break;
                        case 1136584:
                            data.rezervacije[i].brojsobe = 5;
                            break;
                        case 1136587:
                            data.rezervacije[i].brojsobe = 6;
                            break;
                    }
                    switch(data.rezervacije[i].sobe.sobaId) {
                        case 1136581:
                            data.rezervacije[i].opis = content.pozicijaopis1
                            break;
                        case 1136593:
                            data.rezervacije[i].opis = content.pozicijaopis2
                            break;
                        case 1136596:
                            data.rezervacije[i].opis = content.pozicijaopis3
                            break;
                        case 1136590:
                            data.rezervacije[i].opis = content.pozicijaopis4
                            break;
                        case 1136584:
                            data.rezervacije[i].opis = content.pozicijaopis5
                            break;
                        case 1136587:
                            data.rezervacije[i].opis = content.pozicijaopis6
                            break;
                    }
                    
                }
                sobe[i] = data.rezervacije[i];
            }


            setFinalRooms(sobe);       

        })
    }


    
    return ( <body id="cloudbackground" >
        <Helmet>
            <script> gtag('event', 'conversion', 'send_to': 'AW-724945285/D423COvgxesYEIWT19kC', 'transaction_id': '' ); </script>
        </Helmet>
           <Header language={props.language} handleSetLanguage={props.handleSetLanguage}/>

            <br/>
            <br/>
            <br/>
            <br/>
            <h1 style={{"text-decoration":'underline'}}>{content.labelUnosDodatni}</h1>
            <br/>
            <br/>
            <div className='backgroundcolorDataCenteredbigger'>
                
                <h2>{content.confirmationemail}</h2>

                <br/>   
                <h2>{content.sažetakRezervacije} : </h2>
                <br/>
                <p><b>{content.idRez} : </b> &nbsp; {idRez}</p>
                <p><b>{content.rezerviranoDatum} : </b> &nbsp; {rezerviranoDatum}</p>
                <p><b>{content.labelDolazak} : </b> &nbsp; {dolazakDatum}</p>
                <p><b>{content.labelOdlazak} : </b> &nbsp; {odlazakDatum}</p>
                <br/>
                <h3>{content.gost} :</h3>
                <br/>
                <p><b>{content.ime} : </b> &nbsp; {ime}</p>               
                <p><b>{content.prezime} : </b> &nbsp; {prezime}</p>
                <p><b>Email : </b> &nbsp; {email}</p>
                <p><b>{content.država} : </b> &nbsp; {drzava}</p>
                <p><b>{content.napomena} : </b> &nbsp;" {napomena}"</p>


                <p>
                <br/>
                <h3>{finalpaketIme} {content.paketSoba} :</h3>
                <br/>

                {
                    finalRooms.map(final=> <span>

                        <b>
                            1x 
                            &nbsp;
                            {final.sobe.nazivSoba}
                            &nbsp;
                            {final.sobe.maxOdraslih}
                        </b>
                        <i class="bi bi-person-fill"></i> : &nbsp; <br/>


                        1 x {content.bracniKrevet} <br/>
                        {final.sobe.maxOdraslih - 2 } x {content.singlebed}
                        <br/>

                        <p>{content.pinvrata}<b> {final.pinZaVrata} </b>  </p>

                        </span>)
                }
                <br/>
                <hr/>
                <b className='cijena'>
                        {content.cijena} : {finalCijena} €
                </b>
                <br/>
                

                </p>                
            </div>
            <br/>

            <Footer  language={props.language} />
            </body>)
        }
export default PregledRezervacija;
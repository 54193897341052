import IntranetHeader from "./intranetHeader"

function Uredi() {


    return (
        <div>
            <IntranetHeader/>
            <br/><br/><br/><br/><br/><br/><br/><br/>
            <h1>Uredi podatke</h1>
            <span><a href="/promokoduredi">Promokod</a></span><br/>
            <a href="/maillista">Mailing listu za izvještaje</a>
        </div>
    )
}

export default Uredi
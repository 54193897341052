import React from "react";
import { useNavigate } from "react-router-dom";


import './css/Rooms.css'
import jela1 from "./static/jela1.jpg"
import jela2 from "./static/jela2.jpg"
import jela3 from "./static/jela3.jpg"
import jela4 from "./static/jela4.webp"
import korana1 from "./static/korana1.jpg"
import korana2 from "./static/korana2.webp"
import korana3 from "./static/korana3.webp"
import korana4 from "./static/korana4.webp"
import korana5 from "./static/korana5.jpg"


import una1 from "./static/una1.jpg"
import una2 from "./static/una2.webp"
import una3 from "./static/una3.jpg"
import una4 from "./static/una4.jpg"
import una5 from "./static/una5.jpg"
import una6 from "./static/una6.jpg"
import una10 from "./static/una10.jpg"
import una8 from "./static/una8.jpg"
import una9 from "./static/una9.jpg"


import lada1 from "./static/lada1.jpg"
import lada2 from "./static/lada2.jpg"
import lada3 from "./static/lada3.jpg"
import lada4 from "./static/lada4.webp"
import zora1 from "./static/zora1.jpg"
import zora2 from "./static/zora2.jpg"
import zora3 from "./static/zora3.jpg"
import zora4 from "./static/zora4.jpg"
import zora5 from "./static/zora5.webp"
import zora6 from "./static/zora6.jpg"


import lika3 from "./static/lika3.webp"
import lika4 from "./static/lika4.jpeg"
import lika5 from "./static/lika5.jpeg"
import lika6 from "./static/lika6.jpeg"
import lika7 from "./static/lika7.jpeg"
import lika8 from "./static/lika8.jpeg"



function Rooms(props) {

    let content = {
        English: {
            sve:"All",
            kada:"Bath tub",
            kingsize:" Super king size bed (210cm width) ",
			roomview:"Room view",
			bathroom:"Bathroom"
          
        },
        German: {
			sve:"Alles",
            kada:"Luxuriöse Badewanne",
            kingsize:" Super-Kingsize-Bett (210 cm breit) ",
			roomview:"Ein Blick ins Zimmer",
			bathroom:"Badezimmer"

        },
        Croatian: {
            sve:"Sve",
            kada:"Luksuzna kada za kupanje",
            kingsize:" Super king size krevet (210cm širine) ",
			roomview:"Pogled na sobu",
			bathroom:"Kupaonica"

        }
    }


    if(props.language === "English") {
        content = content.English
     } else if(props.language === "Croatian") {
        content = content.Croatian
     } else {
         content = content.German
     }    

    return (
        <div class="menu-box">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="heading-title text-center">
						<h2>{content.istraziteSobe}</h2>
						<p>{content.sobepodnaslov}</p>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="special-menu text-center">
						<div class="button-group filter-button-group">
							<button class="active" data-filter="*">{content.sve}</button>
							<button data-filter=".una">Una</button>
							<button data-filter=".korana">Korana</button>
							<button data-filter=".zora">Zora</button>
							<button data-filter=".jela">Jela</button>
							<button data-filter=".lada">Lada</button>
							<button data-filter=".lika">Lika</button>
						</div>
					</div>
				</div>
			</div>

			
				
			<div class="row special-list">
			<div class="col-lg-4 col-md-6 special-grid lika">
					<div class="gallery-single fix">
						<img src={lika5} class="img-fluid" alt="s" />
						<div class="why-text">
						<h4>Superior room</h4>
							<h5> 100€ - 200€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid lika">
					<div class="gallery-single fix">
						<img src={lika6} class="img-fluid" alt="s" />
						<div class="why-text">
						<h4>Superior room</h4>
							<h5> 100€ - 200€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid lika">
					<div class="gallery-single fix">
						<img src={lika3} class="img-fluid" alt="s" />
						<div class="why-text">
						<h4>Superior room</h4>
							<h5> 100€ - 200€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid lika">
					<div class="gallery-single fix">
						<img src={lika4} class="img-fluid" alt="s" />
						<div class="why-text">
						<h4>Superior room</h4>
							<h5> 100€ - 200€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid lika">
					<div class="gallery-single fix">
						<img src={lika7} class="img-fluid" alt="s" />
						<div class="why-text">
						<h4>Superior room</h4>
							<h5> 100€ - 200€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid lika">
					<div class="gallery-single fix">
						<img src={lika8} class="img-fluid" alt="s" />
						<div class="why-text">
						<h4>Superior room</h4>
							<h5> 100€ - 200€</h5>
						</div>
					</div>
				</div>
				
				<div class="col-lg-4 col-md-6 special-grid jela">
					<div class="gallery-single fix">
						<img src={jela1} class="img-fluid" alt="slika1" />
						<div class="why-text">
							<h4>Deluxe room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>
				
				<div class="col-lg-4 col-md-6 special-grid jela">
					<div class="gallery-single fix">
						<img src={jela2} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Deluxe room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>
				

				<div class="col-lg-4 col-md-6 special-grid jela">
					<div class="gallery-single fix">
						<img src={jela3} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Deluxe room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>

				<div class="col-lg-4 col-md-6 special-grid jela">
					<div class="gallery-single fix">
						<img src={jela4} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Deluxe room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>

				
				
				

				<div class="col-lg-4 col-md-6 special-grid lada">
					<div class="gallery-single fix">
						<img src={lada1} class="img-fluid" alt="s" />
						<div class="why-text">
						<h4>Superior room</h4>
							<h5> 100€ - 200€</h5>
						</div>
					</div>
				</div>
				
				<div class="col-lg-4 col-md-6 special-grid lada">
					<div class="gallery-single fix">
						<img src={lada2} class="img-fluid" alt="s" />
						<div class="why-text">
						<h4>Superior room</h4>
							<h5> 100€ - 200€</h5>
						</div>
					</div>
				</div>

				<div class="col-lg-4 col-md-6 special-grid lada">
					<div class="gallery-single fix">
						<img src={lada3} class="img-fluid" alt="s" />
						<div class="why-text">
						<h4>Superior room</h4>
							<h5> 100€ - 200€</h5>
						</div>
					</div>
				</div>
				
				<div class="col-lg-4 col-md-6 special-grid lada">
					<div class="gallery-single fix">
						<img src={lada4} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Superior room</h4>
							<h5> 100€ - 200€</h5>
						</div>
					</div>
				</div>
				
				<div class="col-lg-4 col-md-6 special-grid zora">
					<div class="gallery-single fix">
						<img src={zora1} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 300€</h5>
						</div>
					</div>
				</div>

				<div class="col-lg-4 col-md-6 special-grid zora">
					<div class="gallery-single fix">
						<img src={zora2} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 300€</h5>
						</div>
					</div>
				</div>

				<div class="col-lg-4 col-md-6 special-grid zora">
					<div class="gallery-single fix">
						<img src={zora3} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 300€</h5>
						</div>
					</div>
				</div>
				
				<div class="col-lg-4 col-md-6 special-grid zora">
					<div class="gallery-single fix">
						<img src={zora4} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 300€</h5>
						</div>
					</div>
				</div>
				
				<div class="col-lg-4 col-md-6 special-grid zora">
					<div class="gallery-single fix">
						<img src={zora5} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 300€</h5>
						</div>
					</div>
				</div>

				<div class="col-lg-4 col-md-6 special-grid zora">
					<div class="gallery-single fix">
						<img src={zora6} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 300€</h5>
						</div>
					</div>
				</div>

				<div class="col-lg-4 col-md-6 special-grid korana">
					<div class="gallery-single fix">
						<img src={korana1} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 300€</h5>
						</div>
					</div>
				</div>

				<div class="col-lg-4 col-md-6 special-grid korana">
					<div class="gallery-single fix">
						<img src={korana3} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 300€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid korana">
					<div class="gallery-single fix">
						<img src={una9} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>

				<div class="col-lg-4 col-md-6 special-grid korana">
					<div class="gallery-single fix">
						<img src={korana2} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 300€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid korana">
					<div class="gallery-single fix">
						<img src={korana5} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>
				
				
				<div class="col-lg-4 col-md-6 special-grid korana">
					<div class="gallery-single fix">
						<img src={korana4} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>
				
				
				<div class="col-lg-4 col-md-6 special-grid una">
					<div class="gallery-single fix">
						<img src={una2} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid una">
					<div class="gallery-single fix">
						<img src={una3} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid una">
					<div class="gallery-single fix">
						<img src={una4} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid una">
					<div class="gallery-single fix">
						<img src={una5} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid una">
					<div class="gallery-single fix">
						<img src={una6} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid una">
					<div class="gallery-single fix">
						<img src={una10} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid una">
					<div class="gallery-single fix">
						<img src={una1} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid una">
					<div class="gallery-single fix">
						<img src={una8} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-6 special-grid una">
					<div class="gallery-single fix">
						<img src={una9} class="img-fluid" alt="s" />
						<div class="why-text">
							<h4>Luxury room</h4>
							<h5> 100€ - 180€</h5>
						</div>
					</div>
				</div>




			</div>
		</div>
	</div>
    )
}


export default Rooms;
import React from "react";
import "./css/footer.css";

function Footer(props) {

        let content = {
          English: {
            Onama:"About us",
            tekstonama:"Newly luxury decorated house in the heart of the Plitvice Lakes, right next to famous lake Kozjak.",
            radnovrijeme:"Check-in hours",
            socials:"Socials",
            kontaktinfo:"Contact Infromation",
            dizajnod:"Design By",
            arr:"All Rights Reserved.",
            ljetno:"1. May  - 1. October",
            zimsko:"1. March - 1. May"

          },
          German: {
            Onama:"Uber uns :",
            tekstonama:"Neu luxuriös eingerichtetes Haus mit 6 Zimmern für den Urlaub. Es liegt im Herzen der Plitvicer Seen, nur 200 Meter über dem Kozjak-See",
            radnovrijeme:"Check-in Zeit",
            socials:"Social media",
            kontaktinfo:"Kontaktinformationen",
            dizajnod:"Design",
            arr:"Alle Rechte vorbehalten.",
            ljetno:"1. Mai - 1. Oktober",
            zimsko:"1. März - 1. Mai"
          },
          Croatian: {
            Onama:"O nama",
            tekstonama:"Novo luksuzno uređena kuća s 6 soba za odmor. Nalaz se u srcu Plitvičkih Jezera, samo 200 metara iznad jezera Kozjak",
            radnovrijeme:"Check-in vrijeme",
            socials:"Društvene mreže",
            kontaktinfo:"Kontakt informacije",
            dizajnod:"Dizajn",
            arr:"Sva prava pridržana.",
            ljetno:"1. svibanj  - 1. listopad",
            zimsko:"1. ožujak - 1. svibanj"
      }
      }

      if(props.language === "English") {
          content = content.English
      } else if(props.language === "Croatian") {
          content = content.Croatian
      } else {
          content = content.German
      }


    return (
      <footer class="footer-area bg-f">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <h3>{content.Onama}</h3>
            <p>{content.tekstonama}</p>
          </div>
          <div class="col-lg-3 col-md-6">
            <h3>{content.radnovrijeme}</h3>
            <p><span class="text-color">{content.ljetno} : </span> 16:00 - 19:00</p>
            <p><span class="text-color">{content.zimsko} : </span> 16:00 - 19:00</p>
          </div>
          <div class="col-lg-3 col-md-6">
            <h3>{content.kontaktinfo}</h3>
            <p class="lead">Plitvica Selo 70a, Croatia</p>
            <p class="lead"><a href="tel:00385998133790">+385 99 813 3790</a></p>
            <p><a href="mailto:info@pearlofplitvice.com"> info@pearlofplitvice.com</a></p>
          </div>
          <div class="col-lg-3 col-md-6">
            <h3>{content.socials}</h3>
            
            <ul class="list-inline f-social">
              <li class="list-inline-item"><a href="https://www.instagram.com/pearlofplitvice/"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
              <li class="list-inline-item"><a href="https://www.instagram.com/pearlofplitvice/"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
      
      <div class="copyright">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <p class="company-name">{content.arr} &copy; 2023 <a href="/">Boutique Villa Pearl of Plitvice</a> {content.dizajnod}  :
            <a  href="https://www.instagram.com/matijavugrinec/?hl=hr"> Matija Vugrinec</a></p>
            </div> 
          </div>
        </div>
      </div>
      
    </footer>
    )
}


export default Footer;
import React from 'react';
import './css/login.css'
import { useState } from 'react';
import AuthHandler from "./AuthHandler";
import { Form, Button, FormLabel } from 'react-bootstrap';

function LoginForm(props) {

    const [loginForm, setLoginForm] = useState({ username: '', password: ''});
    const [error, setError] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);

    function onSubmit(e) {
        e.preventDefault();
        setError("");
        //AuthHandler.logout();
        AuthHandler
            .executeBasicAuthenticationService(loginForm.username, loginForm.password)
            .then((res) => {
                return res.json()
            })
            .then((data) => AuthHandler.registerSuccessfulLogin(loginForm.username, loginForm.password, data.role))
            .then( () => window.location.reload(true) )
            .catch(() => {
                setError("Login failed!");
        })

        
    }

    function onChange(event) {
        const {name, value} = event.target;
        let newForm = {username: loginForm.username, password:loginForm.password};
        newForm[name] = value;
        setLoginForm(newForm);
    }   

    function togglePassword(){
        setPasswordShown(!passwordShown);
    }
       
    return (
        <div className='centerdiv'>
           <div className="container col-md-4 col-md-offset-4 border rounded">
            <h1>Login za osoblje</h1>
            <Form className="mt-3 mb-3" onSubmit={onSubmit}>
                <Form.Group className="col-xs-2">
                    <Form.Label>Korisničko ime</Form.Label>
                    <Form.Control 
                        required
                        type="text"
                        name="username"
                        value={loginForm.username}
                        onChange={onChange}
                        placeholder="Korisničko ime"  
                    />
                </Form.Group>
                <br/>
                <Form.Group>
                    <Form.Label>Lozinka</Form.Label>
                    <Form.Control 
                        required
                        type={passwordShown ? "text" : "password"}
                        name="password"
                        value={loginForm.password}
                        onChange={onChange}
                        placeholder="Lozinka"  
                    />
                </Form.Group>
                <Form.Check type="checkbox" label="Prikaži lozinku" onClick={() => togglePassword()}></Form.Check>
                <hr/>
                <Button className="btn" type="submit">
                    Login
                </Button>
                <div>{error}</div>
            </Form>
        </div>
        </div>
    )
}

export default LoginForm
import React from "react";
import { useState } from 'react';
import axios from 'axios';
import './css/intranet.css'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import "./css/bootstrap.min.css"


function PregledMjeseca() {
    
    const [mjesecpregled,setMjesecpregled] = useState(new Date().getMonth() + 1)
    const [godinapregled,setGodinapregled] = useState(new Date().getFullYear());
    const [podacipopunjenost,setpodacipopunjenost] = useState([]) 
    const [head,setHeader] = useState("");
    const [dodatnidatumi,setdodatnidatumi] = useState([])

    React.useEffect(() => {
        ucitajzamjesec();
    },[])

    function ucitajzamjesec() {

        let mjesecpregledstr = "" + mjesecpregled;
        if(mjesecpregledstr.length == 1) {
            mjesecpregledstr = "0" + mjesecpregledstr
        }        

        let godinapregledstr = "" + godinapregled;
        if(godinapregledstr.length == 1) {
            godinapregledstr = "0" + godinapregledstr
        }      

        console.log("mj = " +mjesecpregledstr+" gd = " +godinapregledstr)

        let danUtjednu = new Date(godinapregledstr+"-"+mjesecpregledstr+"-01").getDay();

        let day = "31"

        switch (mjesecpregledstr) {
            case "01" : day = "31";
                break;
            case "02" :
                if(godinapregledstr == "2024" || godinapregledstr == "2028" || godinapregledstr == "2032" || godinapregledstr == "2036" || godinapregledstr == "2038"){
                    day="29";
                } else {
                    day = "28";
                }
                break;
            case "03" : day = "31";
                break;
            case "04" : day = "30";
                break;
            case "05" : day = "31";
                break;
            case "06" : day = "30";
                break;
            case "07" : day = "31";
                break;
            case "08" : day = "31";
                break;
            case "09" : day = "30";
                break;
            case "10" : day = "31";
                break;
            case "11" : day = "30";
                break;
            case "12" : day = "31";

        }

        let arr = [];

        for(let i = parseInt(day);i<35;i++) {
            arr.push(<span><span><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></span>)
        }

        setdodatnidatumi(arr)

        let header = "";

        switch(danUtjednu) {
            case 0 : 
            header = "ne po ut sr ce pe su"
            break;
            case 1 : header = "pon uto sri čet pet sub ned"
            break;
            case 2 : header = "uto sri čet pet sub ned pon"
            break;
            case 3 : header = "sri čet pet sub ned pon uto"
            break;
            case 4 : header = "čet pet sub ned pon uto sri"
            break;
            case 5 : header = "pet sub ned pon uto sri čet"
            break;
            case 6 : header = "sub ned pon uto sri čet pet"
            break;
        }

        setHeader(header)



        axios.get('/rezervacije?mjesec='+mjesecpregledstr+"&godina="+godinapregledstr)
        .then(res => res.data)
        .then(data => {
            setpodacipopunjenost(data);
        })
    }

    function mjesecMijenja(event) {
        const value = event.target.value;
        console.log(value);
        let godina = value.split("-")[0]
        let mjesec = value.split("-")[1]

        setMjesecpregled(mjesec)
        setGodinapregled(godina)
    } 


    return (
        <div>
            <h2>Pregled popunjenosti po mjesecima</h2>
            <input type={"month"} onChange={mjesecMijenja} />
            <br/><br/>
            <Button onClick={() => ucitajzamjesec()}>Pregledaj</Button>
            <div className="flex">
                {
                    podacipopunjenost.map(soba => <div  key={soba.imeSobe}>
                        <h3>{soba.imeSobe}</h3>
                        <b className="head">{head}</b><br/>
                        {
                            soba.popunjenost.map((dan,index) => 
                                <span> 
                                {(index + 1) % 7 == 0 && <span>
                                    { dan==true && <span className="bgc-yellow">{(index + 1) < 10 && <span>&nbsp;0{index + 1}&nbsp;</span>} {(index + 1) >= 10 && <span>&nbsp;{index + 1}&nbsp;</span>}</span> }
                                    { dan != true && <span>{(index + 1) < 10 && <span>&nbsp;0{index + 1}&nbsp;</span>} {(index + 1) >= 10 && <span>&nbsp;{index + 1}&nbsp;</span>}</span>} <br/></span>}
                                {(index + 1) % 7 != 0 && <span> 
                                { dan==true && <span className="bgc-yellow">{(index + 1) < 10 && <span>&nbsp;0{index + 1}&nbsp;</span>} {(index + 1) >= 10 && <span>&nbsp;{index + 1}&nbsp;</span>}</span>} 
                                { dan != true && <span>{(index + 1) < 10 && <span>&nbsp;0{index + 1}&nbsp;</span>} {(index + 1) >= 10 && <span>&nbsp;{index + 1}&nbsp;</span>}</span>}</span>}  
                                </span> )
                        }

                        
                        {
                            dodatnidatumi                            
                        }


                        </div>)
                }
            </div>
            
        </div>
    )
}

export default PregledMjeseca
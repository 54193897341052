import React from "react";
import Mainpageintranet from "./intranetmainpage";
import ManagerHeader from "./managerheader";

function manager() {

    return <div>
        <ManagerHeader />
        <br/><br/><br/><br/><br/><br/><br/><br/>            <br/><br/><br/><br/><br/><br/><br/><br/>

        <Mainpageintranet/>

    </div>
}

export default manager
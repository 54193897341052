import React from "react";
import axios from 'axios';
import IntranetHeader from "./intranetHeader";
import "./css/promokod.css"
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS
import { Button } from "react-bootstrap";


function Promokod() {

    const [promokodovi, setPromokodovi] = React.useState([]);
    const [sifra,setSifra] = React.useState("");
    const [postotak,setPostotak] = React.useState(0);

 


    const colmunDefs = [
        {field: 'promokodSifra',
        filter: 'agTextColumnFilter',
        filterOptions: ['contains', 'notContains'],
        sortable: true
        },
        {field: 'postotakPopusta',
        editable : true,
        sortable: true},
        {field:'aktivan',
        filter: 'agTextColumnFilter',
        editable : true,
        cellEditor : 'agSelectCellEditor',
        cellEditorParams : {
            values : ['false','true']
        },
        filterOptions: ['contains','notContains'],
        sortable: true}
    ];
    const gridStyle = React.useMemo(() => ({ height: '400px', width: '600px' }), []);
    const gridRef = React.useRef();


    React.useEffect(() => {
        fetchPromokodovi();
    },[])

    function fetchPromokodovi() {
        
        axios.get("/promokodovi")
        .then(d => d.data)
        .then(promokod => { console.log(promokod); setPromokodovi(promokod)})


        console.log(promokodovi)
    }

    function changeSifra(event) {
        const value = event.target.value;
        setSifra(value);
    }

    function changePostotak(event) {
        const value = event.target.value;
        setPostotak(value);
    }

    function izbrisi() {
        let rows =  gridRef.current.api.getSelectedRows();
        for(let i = 0; i < rows.length;i++) {

            let sifratbd = rows[i].promokodSifra;

            axios.delete('/promokod',{
                data : {
                    promokodSifra: sifratbd,
                }       
            })
            .then(res => res.data)
            .then(data => setPromokodovi(data))
            
        }
    }

    function CellValueChanged(e)  {
        console.log(e)
        let row = e.api.getSelectedRows()[0]
            axios.post('/promokod',{

                promokodSifra : row.promokodSifra,
                postotakPopusta: String(row.postotakPopusta),
                aktivan : String(row.aktivan)
            })
        

    }



    function submit(e) {
        e.preventDefault()

        axios.post('/promokod',{
            promokodSifra: sifra,
            postotakPopusta: postotak,
            aktivan : "true"
        })
        .then(res => res.data)
        .then(data => setPromokodovi(data))

        setSifra("")
        setPostotak(0)
    }
    

    
    return <div>
       <IntranetHeader/>
       <br/><br/><br/><br/><br/><br/><br/><br/>

       <h2>Uredi promo kodove</h2>
       <br/>


       <div className="centerTable">
            <div>
            <Button onClick={() => izbrisi()}>Izbrisi odabrane retke</Button><br/><br/>
            <br/>
           <div className="ag-theme-alpine" style={gridStyle}>
                <AgGridReact  onCellValueChanged={() => CellValueChanged(gridRef.current)} ref={gridRef} rowSelection='multiple' editable={true} pagination={true} paginationAutoPageSize={true} rowData={promokodovi} columnDefs={colmunDefs}></AgGridReact>
            </div>
            <br/>
            <div>
                    <td>
                        <input type="text"  onChange={changeSifra} value={sifra}  />
                    </td>
                    <td>
                        <input type="number" onChange={changePostotak} value={postotak} />
                    </td>
                    <td>
                        <button onClick={submit}>
                            Unesi novi Promokod
                        </button>
                    </td>
                </div>
            </div>
        </div>

    </div>
}

export default Promokod
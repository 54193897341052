import React from "react";

function Terms(props) {

        let content = {
          English: {
            Onama:"About us",
            tekstonama:"Newly luxury decorated house in the heart of the Plitvice Lakes, right next to famous lake Kozjak.",
            radnovrijeme:"Check-in hours",
            socials:"Socials",
            kontaktinfo:"Contact Infromation",
            dizajnod:"Design By",
            arr:"All Rights Reserved.",
            ljetno:"1. May  - 1. October",
            zimsko:"1. March - 1. May"

          },
          German: {
            Onama:"Uber uns :",
            tekstonama:"Neu luxuriös eingerichtetes Haus mit 6 Zimmern für den Urlaub. Es liegt im Herzen der Plitvicer Seen, nur 200 Meter über dem Kozjak-See",
            radnovrijeme:"Check-in Zeit",
            socials:"Social media",
            kontaktinfo:"Kontaktinformationen",
            dizajnod:"Design",
            arr:"Alle Rechte vorbehalten.",
            ljetno:"1. Mai - 1. Oktober",
            zimsko:"1. März - 1. Mai"
          },
          Croatian: {
            Onama:"O nama",
            tekstonama:"Novo luksuzno uređena kuća s 6 soba za odmor. Nalaz se u srcu Plitvičkih Jezera, samo 200 metara iznad jezera Kozjak",
            radnovrijeme:"Check-in vrijeme",
            socials:"Društvene mreže",
            kontaktinfo:"Kontakt informacije",
            dizajnod:"Dizajn",
            arr:"Sva prava pridržana.",
            ljetno:"1. svibanj  - 1. listopad",
            zimsko:"1. ožujak - 1. svibanj"
      }
      }

      if(props.language === "English") {
          content = content.English
      } else if(props.language === "Croatian") {
          content = content.Croatian
      } else {
          content = content.German
      }


    return (
      <div>
        <p>
        These Terms and Conditions shall govern any accommodation agreements or any other related contracts entered into between the hotel and the guest（including daytime guests using rooms for teleworking, etc.; the same shall apply hereinafter）, and any matters not stipulated in the Terms and Conditions shall be governed by law and generally established customs.
        </p>
        <p>
        Any usage guidelines and precautions（hereinafter collectively referred to as “Rules”）presented by the hotel in connection with these Terms and Conditions shall, in addition to the Hotel Rules and Regulations established by the hotel and kept in guestrooms, constitute a part of these Terms and Conditions.
        </p>
        <p>
        When reserving a room your name, address, arrival- and departure time and method of payment are required.  
        </p>
        <p>
        Unless otherwise agreed at the time of reservation, all bookings are final and there is no cancellation.
        </p>
        <p>
        A room reservation is binding once it has been confirmed verbally or in writing and you have received a reservation confirmation number. The hotel is entitled to apply different kinds of rules such as reservation fee or credit card guarantee in order to secure the reservation.
        </p>
        <p>
        We follow good manners and hotel rules at the hotel. In case of breaking these rules you may be immediately removed from the premises. In such cases you are still obliged to pay for the accommodation and additional services ordered. You are not eligible to claim a refund for payments already made.
        </p>
        <p>
            The hotel is not responsible for valuables stored in a safety deposit box in your room.
        </p>

      </div>
    )
}


export default Terms;
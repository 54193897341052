import React, { useEffect } from "react";
import { useParams } from 'react-router-dom'
import { useState } from 'react';
import "./css/bootstrap.min.css"
import "./css/style.css"
import "./css/responsive.css"
import "./css/custom.css"
import "./css/checkin.css"

import Header from "./header";
import Footer from "./footer";
import svijeca from "./images/svijeca-plitvice.jpg"
import axios from 'axios';
import { Button } from "react-bootstrap";


function Checkin(props) {

    const [idRez,setIdRez] = useState("");
    const [ime,setIme] = useState("")
    const [prezime,setPrezime] = useState("")
    const [email,setEmail] = useState("")
    const [finalRooms,setFinalRooms] = useState([]);
    const [linksazetak,setLinksazetak] = useState("")
    const [maxOdraslih,setMaxodraslih] = useState(0);
    const [zadanibrojljudi,setzadanibrojljudi] = useState(1);

    const [podaciljudi,setpodaciljudi] = useState([]);
    const [inputljudi,setinputljudi] = useState("");
    const [error,setError] = useState("");


    const {id} = useParams();


    let content = {
        English: {
            idRez:"ID of a Reservation",
            selfcheckin:"Self check-in page.",
            selfcheckinsubtext:"If you fill the data online before the arrival, you will recive a FREE scented candle as a gift when you arrive.",
            trazenipodaci:"Required data",
            checkinsubsubtext:"The code for the door will be auto generated and will be sent to your email address.",
            mainguestdata:"Main guest data",
            ime:"Name",
            prezime:"Surname",
            done:"Done",
            kriviid:"Wrong resevation id",
            gohome:"Click me to go HOME",
            hvalanaunosu:"Thank you for the self check-in. Click the link below to see the Reservation summary",
            sazetak:"Reservation summary and pin codes for the door",
            numofpeople:"Number of people",
            staying:"staying",
            rezerviranesobe:"Reserved rooms",
            kreninaunos:"Apply",
            upisitepodatkepoosobi:"Enter data for every person",
            sve:"All",
            osobabroj:"Person number ",
            spol:"Gender",
            musko:"Male",
            zensko:"Female",
            datumrodenja:"Date of birth",
            drzavljansto:"Citizenship",
            drzavarodenja:"Country of Birth",
            drzavaprebivalista:"Country of residence",
            gradprebivalista:"City of residence",
            gradrodenja:"City of birth",
            brojisprave:"Document number",
            DocumentType:"Document Type",
            osobna:"Identification card (only EU)",
            passport:"Passport",
            errorpodaci:"Error: Please enter all data for all people"
        },
        German: {
			idRez:"Reservierungs-ID",
            selfcheckin:"Self-Check-in-Seite",
            selfcheckinsubtext:"Wenn Sie die Informationen vor Ihrer Ankunft selbst ausfüllen, erhalten Sie bei Ihrer Ankunft in der Villa eine kostenlose Duftkerze als Geschenk.",
            trazenipodaci:"Angeforderte Daten",
            checkinsubsubtext:"Der Code für die Tür wird automatisch generiert und an Ihre E-Mail-Adresse gesendet.",
            mainguestdata:"Informationen über den Hauptgast",
            ime:"Name",
            prezime:"Nachname",
            done:"Fertig",
            kriviid:"Falsche Reservierungs-ID",
            gohome:"Klicken Sie auf mich, um zur HOME-Seite zu gelangen",
            hvalanaunosu:"Vielen Dank für den unabhängigen Check-in. Klicken Sie auf den unten stehenden Link, um die Buchungsübersicht anzuzeigen",
            sazetak:"Buchungsübersicht und Türcodes",
            numofpeople:"Anzahl der Personen",
            staying:"wer bleibt",
            rezerviranesobe:"Reservierte Zimmer",
            kreninaunos:"Gehen Sie zur Eingabe",
            upisitepodatkepoosobi:"Geben Sie Informationen für jede Person ein",
            sve:"alles",
            osobabroj:"Personennummer",
            spol:"Sex",
            musko:"Männlich",
            zensko:"Frau",
            datumrodenja:"Geburtsdatum",
            drzavljansto:"Staatsbürgerschaft",
            drzavarodenja:"Geburtsland",
            drzavaprebivalista:"Land des Wohnsitzes",
            gradprebivalista:"Wohnort",
            gradrodenja:"Geburtsstadt",
            brojisprave:"Dokumentnummer",
            DocumentType:"Dokumentenart",
            osobna:"Personalausweis (nur EU)",
            passport:"Reisepass",
            errorpodaci:"Fehler: Bitte geben Sie alle Informationen für alle Personen ein"
        },
        Croatian: {
            idRez:"ID rezervacije",
            selfcheckin:"Stranica za samostalni check-in",
            selfcheckinsubtext:"Ako samostalno popunite podatke prije dolaska, dobit ćete besplatnu mirisnu svijeću kao dar kada stignete u Villu.",
            trazenipodaci:"Traženi podaci",
            checkinsubsubtext:"Kod za ulazak u sobu auto generirati i poslati na vašu email adresu.",
            mainguestdata:"Podaci o glavnom gostu",
            ime:"Ime",
            prezime:"Prezime",
            done:"Obavljen",
            kriviid:"Krivi id rezervacije",
            gohome:"Klikni na mene za odlazak na POČETNU stranicu",
            hvalanaunosu:"Hvala Vam na samostalnome check-inu. Kliknite na link ispod da bi pogledali sažetak rezervacije",
            sazetak:"Sažetak rezervacije i kodovi za vrata",
            numofpeople:"Broj ljudi",
            staying:"koji odsjeda",
            rezerviranesobe:"Rezervirane sobe",
            kreninaunos:"Kreni na unos",
            upisitepodatkepoosobi:"Upišite podatke za svaku osobu",
            sve:"sve",
            osobabroj:"Osoba broj ",
            spol:"Spol",
            musko:"Musko",
            zensko:"Žensko",
            datumrodenja:"Datum rođenja",
            drzavljansto:"Državljanstvo",
            drzavarodenja:"Država rođenja",
            drzavaprebivalista:"Država prebivališta",
            gradprebivalista:"Grad prebivališta",
            gradrodenja:"Grad rođenja",
            brojisprave:"Broj dokumenta",
            DocumentType:"Tip dokumenta",
            osobna:"Osobna iskaznica (only EU)",
            passport:"Putovnica",
            errorpodaci:"Greška : Molimo unesite sve podatke za sve ljude"
        }
    }

   if(props.language === "English") {
      content = content.English
   } else if(props.language === "Croatian") {
      content = content.Croatian
   } else {
       content = content.German
   }
   useEffect(() => {
    loadcheckin();
    },[]);

    function brojljudisubmit() {
        document.getElementById("inputcheckindiv").style.display = "block";
        
        for(let i = 1; i <= maxOdraslih;i++) {
            podaciljudi.pop();
         }

        for(let i = 1; i <= zadanibrojljudi;i++) {
           podaciljudi.push(i)
        }

        setpodaciljudi(Array.from(podaciljudi))

    }

    class Osobacheckin {
        constructor(ime,prezime,spol,rodenjedatum,drzavarodenja,drz,drzavaprebivalista,gradrodenja,gradprebivalista,brojisprave,tipisprave) {
            this.ime = ime;
            this.prezime = prezime;
            this.spol = spol;
            this.rodenjedatum = rodenjedatum;
            this.drzavarodenja = drzavarodenja;
            this.drz =drz;
            this.drzavaprebivalista = drzavaprebivalista;
            this.gradrodenja = gradrodenja;
            this.gradprebivalista = gradprebivalista;
            this.brojisprave = brojisprave;
            this.tipisprave = tipisprave;
        }
    }

    function checkin() {

        let ljudi = [];
        setError("");


        for(let i = 1; i <= zadanibrojljudi;i++) {
           let ime = document.getElementById("ime"+i).value;
           let prezime = document.getElementById("prezime"+i).value;
           let spol = document.getElementById("spol"+i).value;
           let rodenjedatum = document.getElementById("rodenje"+i).value;
           let drzavarodenja = document.getElementById("drzavarodenja"+i).value;
           let drz = document.getElementById("drzavljanstvo"+i).value;
           let drzavaprebivalista = document.getElementById("drzavaprebivalista"+i).value;
           let gradrodenja = document.getElementById("gradrodenja"+i).value;
           let gradprebivalista = document.getElementById("gradprebivalista"+i).value;
           let brojisprave = document.getElementById("brojisprave"+i).value;
           let tipisprave = document.getElementById("tipisprave"+i).value;

           console.log(ime + " " + prezime + " " + spol + " " + rodenjedatum + " " +drzavarodenja + " " + drz + " " + drzavaprebivalista + " " + gradrodenja + " " +gradprebivalista + " " + brojisprave + " " + tipisprave);


           if(ime == "" || prezime == "" || rodenjedatum == "" || rodenjedatum == "" || drzavarodenja == "AFG" || drz == "AFG " || drzavaprebivalista == "AFG" || gradrodenja == "" || gradprebivalista == "" || brojisprave.length < 3) {
               setError(content.errorpodaci);
               return;
           }

           let osoba = new Osobacheckin(ime,prezime,spol,rodenjedatum,drzavarodenja,drz,drzavaprebivalista,gradrodenja,gradprebivalista,brojisprave,tipisprave);
           ljudi.push(osoba);
        }

        axios.post('/novicheckin', {
            ljudi: ljudi,
            zadanibrojljudi:zadanibrojljudi,
            idRez:idRez
        }).then((res) => console.log(res))

        document.getElementById("datanotneeded").style.display = "block";
		setLinksazetak("/rezervacija/"+idRez);
        document.getElementById("dataneeded").style.display = "none";

        console.log(ljudi);
    }

    function filter(id) {

        for(let i = 1; i <= zadanibrojljudi;i++){
            if(i == id) {
                document.getElementById("data"+id).style.display="block";
            } else {
                document.getElementById("data"+i).style.display="none";
            }
        }
    }   

    function changebrojljudi(event){
        const val = event.target.value;

        
        if(val > maxOdraslih) {
            setzadanibrojljudi(maxOdraslih);
        } else {
            setzadanibrojljudi(val);
        }

    }

   function loadcheckin() {
       //postoji li taj id?
       // je li check in već obavljen?
       //ako nije kolko max može upisati

       axios.get('/reservation?id='+id)
       .then((res) => res.data)
       .then(data => {
            if(data === "") {
                document.getElementById("cover").style.display = "block";
                return;
            }
           setIdRez(id)
           setIme(data.rezervacije[0].gost.ime)
           setPrezime(data.rezervacije[0].gost.prezime)
           setEmail(data.rezervacije[0].gost.email)

           if(data.rezervacije[0].checked_in === true) {
                document.getElementById("datanotneeded").style.display = "block";
                setLinksazetak("/rezervacija/"+id);
           } else {
                document.getElementById("dataneeded").style.display = "block";
           }

           let sobe = [];
           let maxljudi = 0;

           for(let i = 0; i < data.rezervacije.length;i++) {
               maxljudi += data.rezervacije[i].brojOdraslih;
			   maxljudi += data.rezervacije[i].brojDjece;

               if(data.rezervacije[i].pinZaVrata === 0) {
                   data.rezervacije[i].pinZaVrata = content.molimocheckin;
               } 
               sobe[i] = data.rezervacije[i];
           }

           console.log(maxljudi);
           setMaxodraslih(maxljudi);
           setFinalRooms(sobe);       

       }).catch((err) => {
           console.log("ERORR : " + err);
       })

   }


    return (
        <div>
            <Header language={props.language} handleSetLanguage={props.handleSetLanguage}/>
            <div id="cover">
                <div className="center">
                    {content.kriviid}
                </div>
                <br/>
                <a href="/">{content.gohome}</a>

            </div>
            <br/><br/><br/><br/><br/><br/><br/>
            <h1 className="underline">{content.selfcheckin}</h1>
            <br/>
            <img className=" img-container200 rounded" alt={"svijeca"} src={svijeca}></img>
            <br/><br/>
            <h4>{content.selfcheckinsubtext}</h4>
            <h5>{content.checkinsubsubtext}</h5>
            <br/>
            <div>
                <h2 className="underline">{content.mainguestdata}</h2>
                <div class="container border border-dark">
                    <div className="row">
                        <div class="text-right text-large col-md">
                            {content.idRez} :
                        </div>
                        <div class="text-left text-large col-md">
                        {idRez}
                        </div>
                    </div>
                    <div className="row">
                    <div class="text-right text-large col-md">
                            {content.ime} :
                        </div>
                        <div class="text-left text-large col-md">
                        {ime}
                        </div>
                    </div>
                    <div className="row">
                    <div class="text-right text-large col-md">
                            {content.prezime} :
                        </div>
                        <div class="text-left text-large col-md">
                        {prezime}
                        </div>
                    </div>
                    <div className="row">
                    <div class="text-right text-large col-md">
                            Email :
                        </div>
                        <div class="text-left text-large col-md">
                        {email}
                        </div>
                    </div>
                    <div>
                        <hr/>
                        <h4>{content.rezerviranesobe}</h4>
                        {
                            finalRooms.map(final=> <div>

                                <b>
                                    1x 
                                    &nbsp;
                                    {final.sobe.nazivSoba}
                                    &nbsp;
                                    {final.sobe.maxOdraslih}
                                </b>
                                <i class="bi bi-person-fill"></i><br/>
                            </div>
                            )
                        }
                    </div>
                    <br/>
                </div>
                <br/>
            </div>
            



            <div id="dataneeded">

                <h2 className="underline">{content.trazenipodaci}</h2>

                <p>
                    Max {content.numofpeople} : {maxOdraslih}
                </p>
                <label>{content.numofpeople} {content.staying} :&nbsp;</label>
                <input min={0} max={maxOdraslih} type={"number"} onChange={changebrojljudi} value={zadanibrojljudi}/>
                <i class="bi bi-person-fill"></i>
                <br/>
                <Button onClick={() => brojljudisubmit()}>{content.kreninaunos}</Button>

                <div id="inputcheckindiv">
                    <div class="menu-box">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="heading-title text-center">
                                        <h2>{content.upisitepodatkepoosobi}</h2>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="special-menu text-center">
                                        <div class="button-group filter-button-group">
                                            {
                                                podaciljudi.map((array) => <button onClick={ () => { filter(array) }} key={array}>
                                                    {content.osobabroj} {array}
                                                </button>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="special-list">

                                {
                                    podaciljudi.map((array) =>
                                        <div  style={{display: "none"}} id={"data"+array} className={"special-grid"}>
                                            <div class="flex-container">
                                                
                                                <label class="text-right">{content.ime}:</label>
                                                <input id={"ime"+array} type={"text"} />
                                            </div>
                                            <br/>
                                            <div class="flex-container">   
                                                <label>{content.prezime}:</label>
                                                <input id={"prezime"+array} type={"text"} />
                                            </div>
                                            <br/>
                                            <div class="flex-container">   
                                                <label>{content.spol}:</label>
                                                <select id={"spol"+array}>
                                                    <option value={"M"}>{content.musko}</option>  
                                                    <option value={"F"}>{content.zensko}</option>  
                                                </select>
                                            </div>
                                            <br/>
                                            <div class="flex-container">   
                                                <label>{content.datumrodenja}:</label>
                                                <input id={"rodenje"+array} type={"date"} />
                                            </div>
                                            <br/>
                                            <div class="flex-container">   
                                                <label>{content.drzavljansto}:</label>
<select id={"drzavljanstvo"+array}>
	<option value="AUT">Austria</option>
	<option value="BEL">Belgium</option>
	<option value="HRV">Croatia</option>
	<option value="CZE">Czech Republic</option>
	<option value="FRA">France</option>
	<option value="DEU">Germany</option>
	<option value="HUN">Hungary</option>
	<option value="ITA">Italy</option>
	<option value="NLD">Netherlands</option>
	<option value="POL">Poland</option>
	<option value="PRT">Portugal</option>
	<option value="SVK">Slovakia</option>
	<option value="SVN">Slovenia</option>
	<option value="ESP">Spain</option>
	<option value="CHE">Switzerland</option>
	<option value="GBR">United Kingdom</option>
	<option value="USA">United States</option>

	<option value="AFG">Afghanistan</option>
	<option value="ALA">Åland Islands</option>
	<option value="ALB">Albania</option>
	<option value="DZA">Algeria</option>
	<option value="ASM">American Samoa</option>
	<option value="AND">Andorra</option>
	<option value="AGO">Angola</option>
	<option value="AIA">Anguilla</option>
	<option value="ATA">Antarctica</option>
	<option value="ATG">Antigua and Barbuda</option>
	<option value="ARG">Argentina</option>
	<option value="ARM">Armenia</option>
	<option value="ABW">Aruba</option>
	<option value="AUS">Australia</option>
	<option value="AUT">Austria</option>
	<option value="AZE">Azerbaijan</option>
	<option value="BHS">Bahamas</option>
	<option value="BHR">Bahrain</option>
	<option value="BGD">Bangladesh</option>
	<option value="BRB">Barbados</option>
	<option value="BLR">Belarus</option>
	<option value="BEL">Belgium</option>
	<option value="BLZ">Belize</option>
	<option value="BEN">Benin</option>
	<option value="BMU">Bermuda</option>
	<option value="BTN">Bhutan</option>
	<option value="BOL">Bolivia, Plurinational State of</option>
	<option value="BES">Bonaire, Sint Eustatius and Saba</option>
	<option value="BIH">Bosnia and Herzegovina</option>
	<option value="BWA">Botswana</option>
	<option value="BVT">Bouvet Island</option>
	<option value="BRA">Brazil</option>
	<option value="IOT">British Indian Ocean Territory</option>
	<option value="BRN">Brunei Darussalam</option>
	<option value="BGR">Bulgaria</option>
	<option value="BFA">Burkina Faso</option>
	<option value="BDI">Burundi</option>
	<option value="KHM">Cambodia</option>
	<option value="CMR">Cameroon</option>
	<option value="CAN">Canada</option>
	<option value="CPV">Cape Verde</option>
	<option value="CYM">Cayman Islands</option>
	<option value="CAF">Central African Republic</option>
	<option value="TCD">Chad</option>
	<option value="CHL">Chile</option>
	<option value="CHN">China</option>
	<option value="CXR">Christmas Island</option>
	<option value="CCK">Cocos (Keeling) Islands</option>
	<option value="COL">Colombia</option>
	<option value="COM">Comoros</option>
	<option value="COG">Congo</option>
	<option value="COD">Congo, the Democratic Republic of the</option>
	<option value="COK">Cook Islands</option>
	<option value="CRI">Costa Rica</option>
	<option value="CIV">Côte d'Ivoire</option>
	<option value="HRV">Croatia</option>
	<option value="CUB">Cuba</option>
	<option value="CUW">Curaçao</option>
	<option value="CYP">Cyprus</option>
	<option value="CZE">Czech Republic</option>
	<option value="DNK">Denmark</option>
	<option value="DJI">Djibouti</option>
	<option value="DMA">Dominica</option>
	<option value="DOM">Dominican Republic</option>
	<option value="ECU">Ecuador</option>
	<option value="EGY">Egypt</option>
	<option value="SLV">El Salvador</option>
	<option value="GNQ">Equatorial Guinea</option>
	<option value="ERI">Eritrea</option>
	<option value="EST">Estonia</option>
	<option value="ETH">Ethiopia</option>
	<option value="FLK">Falkland Islands (Malvinas)</option>
	<option value="FRO">Faroe Islands</option>
	<option value="FJI">Fiji</option>
	<option value="FIN">Finland</option>
	<option value="FRA">France</option>
	<option value="GUF">French Guiana</option>
	<option value="PYF">French Polynesia</option>
	<option value="ATF">French Southern Territories</option>
	<option value="GAB">Gabon</option>
	<option value="GMB">Gambia</option>
	<option value="GEO">Georgia</option>
	<option value="DEU">Germany</option>
	<option value="GHA">Ghana</option>
	<option value="GIB">Gibraltar</option>
	<option value="GRC">Greece</option>
	<option value="GRL">Greenland</option>
	<option value="GRD">Grenada</option>
	<option value="GLP">Guadeloupe</option>
	<option value="GUM">Guam</option>
	<option value="GTM">Guatemala</option>
	<option value="GGY">Guernsey</option>
	<option value="GIN">Guinea</option>
	<option value="GNB">Guinea-Bissau</option>
	<option value="GUY">Guyana</option>
	<option value="HTI">Haiti</option>
	<option value="HMD">Heard Island and McDonald Islands</option>
	<option value="VAT">Holy See (Vatican City State)</option>
	<option value="HND">Honduras</option>
	<option value="HKG">Hong Kong</option>
	<option value="HUN">Hungary</option>
	<option value="ISL">Iceland</option>
	<option value="IND">India</option>
	<option value="IDN">Indonesia</option>
	<option value="IRN">Iran, Islamic Republic of</option>
	<option value="IRQ">Iraq</option>
	<option value="IRL">Ireland</option>
	<option value="IMN">Isle of Man</option>
	<option value="ISR">Israel</option>
	<option value="ITA">Italy</option>
	<option value="JAM">Jamaica</option>
	<option value="JPN">Japan</option>
	<option value="JEY">Jersey</option>
	<option value="JOR">Jordan</option>
	<option value="KAZ">Kazakhstan</option>
	<option value="KEN">Kenya</option>
	<option value="KIR">Kiribati</option>
	<option value="PRK">Korea, Democratic People's Republic of</option>
	<option value="KOR">Korea, Republic of</option>
	<option value="KWT">Kuwait</option>
	<option value="KGZ">Kyrgyzstan</option>
	<option value="LAO">Lao People's Democratic Republic</option>
	<option value="LVA">Latvia</option>
	<option value="LBN">Lebanon</option>
	<option value="LSO">Lesotho</option>
	<option value="LBR">Liberia</option>
	<option value="LBY">Libya</option>
	<option value="LIE">Liechtenstein</option>
	<option value="LTU">Lithuania</option>
	<option value="LUX">Luxembourg</option>
	<option value="MAC">Macao</option>
	<option value="MKD">Macedonia, the former Yugoslav Republic of</option>
	<option value="MDG">Madagascar</option>
	<option value="MWI">Malawi</option>
	<option value="MYS">Malaysia</option>
	<option value="MDV">Maldives</option>
	<option value="MLI">Mali</option>
	<option value="MLT">Malta</option>
	<option value="MHL">Marshall Islands</option>
	<option value="MTQ">Martinique</option>
	<option value="MRT">Mauritania</option>
	<option value="MUS">Mauritius</option>
	<option value="MYT">Mayotte</option>
	<option value="MEX">Mexico</option>
	<option value="FSM">Micronesia, Federated States of</option>
	<option value="MDA">Moldova, Republic of</option>
	<option value="MCO">Monaco</option>
	<option value="MNG">Mongolia</option>
	<option value="MNE">Montenegro</option>
	<option value="MSR">Montserrat</option>
	<option value="MAR">Morocco</option>
	<option value="MOZ">Mozambique</option>
	<option value="MMR">Myanmar</option>
	<option value="NAM">Namibia</option>
	<option value="NRU">Nauru</option>
	<option value="NPL">Nepal</option>
	<option value="NLD">Netherlands</option>
	<option value="NCL">New Caledonia</option>
	<option value="NZL">New Zealand</option>
	<option value="NIC">Nicaragua</option>
	<option value="NER">Niger</option>
	<option value="NGA">Nigeria</option>
	<option value="NIU">Niue</option>
	<option value="NFK">Norfolk Island</option>
	<option value="MNP">Northern Mariana Islands</option>
	<option value="NOR">Norway</option>
	<option value="OMN">Oman</option>
	<option value="PAK">Pakistan</option>
	<option value="PLW">Palau</option>
	<option value="PSE">Palestinian Territory, Occupied</option>
	<option value="PAN">Panama</option>
	<option value="PNG">Papua New Guinea</option>
	<option value="PRY">Paraguay</option>
	<option value="PER">Peru</option>
	<option value="PHL">Philippines</option>
	<option value="PCN">Pitcairn</option>
	<option value="POL">Poland</option>
	<option value="PRT">Portugal</option>
	<option value="PRI">Puerto Rico</option>
	<option value="QAT">Qatar</option>
	<option value="REU">Réunion</option>
	<option value="ROU">Romania</option>
	<option value="RUS">Russian Federation</option>
	<option value="RWA">Rwanda</option>
	<option value="BLM">Saint Barthélemy</option>
	<option value="SHN">Saint Helena, Ascension and Tristan da Cunha</option>
	<option value="KNA">Saint Kitts and Nevis</option>
	<option value="LCA">Saint Lucia</option>
	<option value="MAF">Saint Martin (French part)</option>
	<option value="SPM">Saint Pierre and Miquelon</option>
	<option value="VCT">Saint Vincent and the Grenadines</option>
	<option value="WSM">Samoa</option>
	<option value="SMR">San Marino</option>
	<option value="STP">Sao Tome and Principe</option>
	<option value="SAU">Saudi Arabia</option>
	<option value="SEN">Senegal</option>
	<option value="SRB">Serbia</option>
	<option value="SYC">Seychelles</option>
	<option value="SLE">Sierra Leone</option>
	<option value="SGP">Singapore</option>
	<option value="SXM">Sint Maarten (Dutch part)</option>
	<option value="SVK">Slovakia</option>
	<option value="SVN">Slovenia</option>
	<option value="SLB">Solomon Islands</option>
	<option value="SOM">Somalia</option>
	<option value="ZAF">South Africa</option>
	<option value="SGS">South Georgia and the South Sandwich Islands</option>
	<option value="SSD">South Sudan</option>
	<option value="ESP">Spain</option>
	<option value="LKA">Sri Lanka</option>
	<option value="SDN">Sudan</option>
	<option value="SUR">Suriname</option>
	<option value="SJM">Svalbard and Jan Mayen</option>
	<option value="SWZ">Swaziland</option>
	<option value="SWE">Sweden</option>
	<option value="CHE">Switzerland</option>
	<option value="SYR">Syrian Arab Republic</option>
	<option value="TWN">Taiwan, Province of China</option>
	<option value="TJK">Tajikistan</option>
	<option value="TZA">Tanzania, United Republic of</option>
	<option value="THA">Thailand</option>
	<option value="TLS">Timor-Leste</option>
	<option value="TGO">Togo</option>
	<option value="TKL">Tokelau</option>
	<option value="TON">Tonga</option>
	<option value="TTO">Trinidad and Tobago</option>
	<option value="TUN">Tunisia</option>
	<option value="TUR">Turkey</option>
	<option value="TKM">Turkmenistan</option>
	<option value="TCA">Turks and Caicos Islands</option>
	<option value="TUV">Tuvalu</option>
	<option value="UGA">Uganda</option>
	<option value="UKR">Ukraine</option>
	<option value="ARE">United Arab Emirates</option>
	<option value="GBR">United Kingdom</option>
	<option value="USA">United States</option>
	<option value="UMI">United States Minor Outlying Islands</option>
	<option value="URY">Uruguay</option>
	<option value="UZB">Uzbekistan</option>
	<option value="VUT">Vanuatu</option>
	<option value="VEN">Venezuela, Bolivarian Republic of</option>
	<option value="VNM">Viet Nam</option>
	<option value="VGB">Virgin Islands, British</option>
	<option value="VIR">Virgin Islands, U.S.</option>
	<option value="WLF">Wallis and Futuna</option>
	<option value="ESH">Western Sahara</option>
	<option value="YEM">Yemen</option>
	<option value="ZMB">Zambia</option>
	<option value="ZWE">Zimbabwe</option>
</select>
                                            </div>
                                            <br/>
                                            <div class="flex-container">   
                                                <label>{content.drzavarodenja}:</label>
<select id={"drzavarodenja"+array}>
	<option value="AUT">Austria</option>
	<option value="BEL">Belgium</option>
	<option value="HRV">Croatia</option>
	<option value="CZE">Czech Republic</option>
	<option value="FRA">France</option>
	<option value="DEU">Germany</option>
	<option value="HUN">Hungary</option>
	<option value="ITA">Italy</option>
	<option value="NLD">Netherlands</option>
	<option value="POL">Poland</option>
	<option value="PRT">Portugal</option>
	<option value="SVK">Slovakia</option>
	<option value="SVN">Slovenia</option>
	<option value="ESP">Spain</option>
	<option value="CHE">Switzerland</option>
	<option value="GBR">United Kingdom</option>
	<option value="USA">United States</option>

	<option value="AFG">Afghanistan</option>
	<option value="ALA">Åland Islands</option>
	<option value="ALB">Albania</option>
	<option value="DZA">Algeria</option>
	<option value="ASM">American Samoa</option>
	<option value="AND">Andorra</option>
	<option value="AGO">Angola</option>
	<option value="AIA">Anguilla</option>
	<option value="ATA">Antarctica</option>
	<option value="ATG">Antigua and Barbuda</option>
	<option value="ARG">Argentina</option>
	<option value="ARM">Armenia</option>
	<option value="ABW">Aruba</option>
	<option value="AUS">Australia</option>
	<option value="AUT">Austria</option>
	<option value="AZE">Azerbaijan</option>
	<option value="BHS">Bahamas</option>
	<option value="BHR">Bahrain</option>
	<option value="BGD">Bangladesh</option>
	<option value="BRB">Barbados</option>
	<option value="BLR">Belarus</option>
	<option value="BEL">Belgium</option>
	<option value="BLZ">Belize</option>
	<option value="BEN">Benin</option>
	<option value="BMU">Bermuda</option>
	<option value="BTN">Bhutan</option>
	<option value="BOL">Bolivia, Plurinational State of</option>
	<option value="BES">Bonaire, Sint Eustatius and Saba</option>
	<option value="BIH">Bosnia and Herzegovina</option>
	<option value="BWA">Botswana</option>
	<option value="BVT">Bouvet Island</option>
	<option value="BRA">Brazil</option>
	<option value="IOT">British Indian Ocean Territory</option>
	<option value="BRN">Brunei Darussalam</option>
	<option value="BGR">Bulgaria</option>
	<option value="BFA">Burkina Faso</option>
	<option value="BDI">Burundi</option>
	<option value="KHM">Cambodia</option>
	<option value="CMR">Cameroon</option>
	<option value="CAN">Canada</option>
	<option value="CPV">Cape Verde</option>
	<option value="CYM">Cayman Islands</option>
	<option value="CAF">Central African Republic</option>
	<option value="TCD">Chad</option>
	<option value="CHL">Chile</option>
	<option value="CHN">China</option>
	<option value="CXR">Christmas Island</option>
	<option value="CCK">Cocos (Keeling) Islands</option>
	<option value="COL">Colombia</option>
	<option value="COM">Comoros</option>
	<option value="COG">Congo</option>
	<option value="COD">Congo, the Democratic Republic of the</option>
	<option value="COK">Cook Islands</option>
	<option value="CRI">Costa Rica</option>
	<option value="CIV">Côte d'Ivoire</option>
	<option value="HRV">Croatia</option>
	<option value="CUB">Cuba</option>
	<option value="CUW">Curaçao</option>
	<option value="CYP">Cyprus</option>
	<option value="CZE">Czech Republic</option>
	<option value="DNK">Denmark</option>
	<option value="DJI">Djibouti</option>
	<option value="DMA">Dominica</option>
	<option value="DOM">Dominican Republic</option>
	<option value="ECU">Ecuador</option>
	<option value="EGY">Egypt</option>
	<option value="SLV">El Salvador</option>
	<option value="GNQ">Equatorial Guinea</option>
	<option value="ERI">Eritrea</option>
	<option value="EST">Estonia</option>
	<option value="ETH">Ethiopia</option>
	<option value="FLK">Falkland Islands (Malvinas)</option>
	<option value="FRO">Faroe Islands</option>
	<option value="FJI">Fiji</option>
	<option value="FIN">Finland</option>
	<option value="FRA">France</option>
	<option value="GUF">French Guiana</option>
	<option value="PYF">French Polynesia</option>
	<option value="ATF">French Southern Territories</option>
	<option value="GAB">Gabon</option>
	<option value="GMB">Gambia</option>
	<option value="GEO">Georgia</option>
	<option value="DEU">Germany</option>
	<option value="GHA">Ghana</option>
	<option value="GIB">Gibraltar</option>
	<option value="GRC">Greece</option>
	<option value="GRL">Greenland</option>
	<option value="GRD">Grenada</option>
	<option value="GLP">Guadeloupe</option>
	<option value="GUM">Guam</option>
	<option value="GTM">Guatemala</option>
	<option value="GGY">Guernsey</option>
	<option value="GIN">Guinea</option>
	<option value="GNB">Guinea-Bissau</option>
	<option value="GUY">Guyana</option>
	<option value="HTI">Haiti</option>
	<option value="HMD">Heard Island and McDonald Islands</option>
	<option value="VAT">Holy See (Vatican City State)</option>
	<option value="HND">Honduras</option>
	<option value="HKG">Hong Kong</option>
	<option value="HUN">Hungary</option>
	<option value="ISL">Iceland</option>
	<option value="IND">India</option>
	<option value="IDN">Indonesia</option>
	<option value="IRN">Iran, Islamic Republic of</option>
	<option value="IRQ">Iraq</option>
	<option value="IRL">Ireland</option>
	<option value="IMN">Isle of Man</option>
	<option value="ISR">Israel</option>
	<option value="ITA">Italy</option>
	<option value="JAM">Jamaica</option>
	<option value="JPN">Japan</option>
	<option value="JEY">Jersey</option>
	<option value="JOR">Jordan</option>
	<option value="KAZ">Kazakhstan</option>
	<option value="KEN">Kenya</option>
	<option value="KIR">Kiribati</option>
	<option value="PRK">Korea, Democratic People's Republic of</option>
	<option value="KOR">Korea, Republic of</option>
	<option value="KWT">Kuwait</option>
	<option value="KGZ">Kyrgyzstan</option>
	<option value="LAO">Lao People's Democratic Republic</option>
	<option value="LVA">Latvia</option>
	<option value="LBN">Lebanon</option>
	<option value="LSO">Lesotho</option>
	<option value="LBR">Liberia</option>
	<option value="LBY">Libya</option>
	<option value="LIE">Liechtenstein</option>
	<option value="LTU">Lithuania</option>
	<option value="LUX">Luxembourg</option>
	<option value="MAC">Macao</option>
	<option value="MKD">Macedonia, the former Yugoslav Republic of</option>
	<option value="MDG">Madagascar</option>
	<option value="MWI">Malawi</option>
	<option value="MYS">Malaysia</option>
	<option value="MDV">Maldives</option>
	<option value="MLI">Mali</option>
	<option value="MLT">Malta</option>
	<option value="MHL">Marshall Islands</option>
	<option value="MTQ">Martinique</option>
	<option value="MRT">Mauritania</option>
	<option value="MUS">Mauritius</option>
	<option value="MYT">Mayotte</option>
	<option value="MEX">Mexico</option>
	<option value="FSM">Micronesia, Federated States of</option>
	<option value="MDA">Moldova, Republic of</option>
	<option value="MCO">Monaco</option>
	<option value="MNG">Mongolia</option>
	<option value="MNE">Montenegro</option>
	<option value="MSR">Montserrat</option>
	<option value="MAR">Morocco</option>
	<option value="MOZ">Mozambique</option>
	<option value="MMR">Myanmar</option>
	<option value="NAM">Namibia</option>
	<option value="NRU">Nauru</option>
	<option value="NPL">Nepal</option>
	<option value="NLD">Netherlands</option>
	<option value="NCL">New Caledonia</option>
	<option value="NZL">New Zealand</option>
	<option value="NIC">Nicaragua</option>
	<option value="NER">Niger</option>
	<option value="NGA">Nigeria</option>
	<option value="NIU">Niue</option>
	<option value="NFK">Norfolk Island</option>
	<option value="MNP">Northern Mariana Islands</option>
	<option value="NOR">Norway</option>
	<option value="OMN">Oman</option>
	<option value="PAK">Pakistan</option>
	<option value="PLW">Palau</option>
	<option value="PSE">Palestinian Territory, Occupied</option>
	<option value="PAN">Panama</option>
	<option value="PNG">Papua New Guinea</option>
	<option value="PRY">Paraguay</option>
	<option value="PER">Peru</option>
	<option value="PHL">Philippines</option>
	<option value="PCN">Pitcairn</option>
	<option value="POL">Poland</option>
	<option value="PRT">Portugal</option>
	<option value="PRI">Puerto Rico</option>
	<option value="QAT">Qatar</option>
	<option value="REU">Réunion</option>
	<option value="ROU">Romania</option>
	<option value="RUS">Russian Federation</option>
	<option value="RWA">Rwanda</option>
	<option value="BLM">Saint Barthélemy</option>
	<option value="SHN">Saint Helena, Ascension and Tristan da Cunha</option>
	<option value="KNA">Saint Kitts and Nevis</option>
	<option value="LCA">Saint Lucia</option>
	<option value="MAF">Saint Martin (French part)</option>
	<option value="SPM">Saint Pierre and Miquelon</option>
	<option value="VCT">Saint Vincent and the Grenadines</option>
	<option value="WSM">Samoa</option>
	<option value="SMR">San Marino</option>
	<option value="STP">Sao Tome and Principe</option>
	<option value="SAU">Saudi Arabia</option>
	<option value="SEN">Senegal</option>
	<option value="SRB">Serbia</option>
	<option value="SYC">Seychelles</option>
	<option value="SLE">Sierra Leone</option>
	<option value="SGP">Singapore</option>
	<option value="SXM">Sint Maarten (Dutch part)</option>
	<option value="SVK">Slovakia</option>
	<option value="SVN">Slovenia</option>
	<option value="SLB">Solomon Islands</option>
	<option value="SOM">Somalia</option>
	<option value="ZAF">South Africa</option>
	<option value="SGS">South Georgia and the South Sandwich Islands</option>
	<option value="SSD">South Sudan</option>
	<option value="ESP">Spain</option>
	<option value="LKA">Sri Lanka</option>
	<option value="SDN">Sudan</option>
	<option value="SUR">Suriname</option>
	<option value="SJM">Svalbard and Jan Mayen</option>
	<option value="SWZ">Swaziland</option>
	<option value="SWE">Sweden</option>
	<option value="CHE">Switzerland</option>
	<option value="SYR">Syrian Arab Republic</option>
	<option value="TWN">Taiwan, Province of China</option>
	<option value="TJK">Tajikistan</option>
	<option value="TZA">Tanzania, United Republic of</option>
	<option value="THA">Thailand</option>
	<option value="TLS">Timor-Leste</option>
	<option value="TGO">Togo</option>
	<option value="TKL">Tokelau</option>
	<option value="TON">Tonga</option>
	<option value="TTO">Trinidad and Tobago</option>
	<option value="TUN">Tunisia</option>
	<option value="TUR">Turkey</option>
	<option value="TKM">Turkmenistan</option>
	<option value="TCA">Turks and Caicos Islands</option>
	<option value="TUV">Tuvalu</option>
	<option value="UGA">Uganda</option>
	<option value="UKR">Ukraine</option>
	<option value="ARE">United Arab Emirates</option>
	<option value="GBR">United Kingdom</option>
	<option value="USA">United States</option>
	<option value="UMI">United States Minor Outlying Islands</option>
	<option value="URY">Uruguay</option>
	<option value="UZB">Uzbekistan</option>
	<option value="VUT">Vanuatu</option>
	<option value="VEN">Venezuela, Bolivarian Republic of</option>
	<option value="VNM">Viet Nam</option>
	<option value="VGB">Virgin Islands, British</option>
	<option value="VIR">Virgin Islands, U.S.</option>
	<option value="WLF">Wallis and Futuna</option>
	<option value="ESH">Western Sahara</option>
	<option value="YEM">Yemen</option>
	<option value="ZMB">Zambia</option>
	<option value="ZWE">Zimbabwe</option>
</select>
                                            </div>
                                            <br/>
                                            <div class="flex-container">   
                                                <label>{content.drzavaprebivalista}:</label>
<select id={"drzavaprebivalista"+array}>
	<option value="AUT">Austria</option>
	<option value="BEL">Belgium</option>
	<option value="HRV">Croatia</option>
	<option value="CZE">Czech Republic</option>
	<option value="FRA">France</option>
	<option value="DEU">Germany</option>
	<option value="HUN">Hungary</option>
	<option value="ITA">Italy</option>
	<option value="NLD">Netherlands</option>
	<option value="POL">Poland</option>
	<option value="PRT">Portugal</option>
	<option value="SVK">Slovakia</option>
	<option value="SVN">Slovenia</option>
	<option value="ESP">Spain</option>
	<option value="CHE">Switzerland</option>
	<option value="GBR">United Kingdom</option>
	<option value="USA">United States</option>

	<option value="AFG">Afghanistan</option>
	<option value="ALA">Åland Islands</option>
	<option value="ALB">Albania</option>
	<option value="DZA">Algeria</option>
	<option value="ASM">American Samoa</option>
	<option value="AND">Andorra</option>
	<option value="AGO">Angola</option>
	<option value="AIA">Anguilla</option>
	<option value="ATA">Antarctica</option>
	<option value="ATG">Antigua and Barbuda</option>
	<option value="ARG">Argentina</option>
	<option value="ARM">Armenia</option>
	<option value="ABW">Aruba</option>
	<option value="AUS">Australia</option>
	<option value="AUT">Austria</option>
	<option value="AZE">Azerbaijan</option>
	<option value="BHS">Bahamas</option>
	<option value="BHR">Bahrain</option>
	<option value="BGD">Bangladesh</option>
	<option value="BRB">Barbados</option>
	<option value="BLR">Belarus</option>
	<option value="BEL">Belgium</option>
	<option value="BLZ">Belize</option>
	<option value="BEN">Benin</option>
	<option value="BMU">Bermuda</option>
	<option value="BTN">Bhutan</option>
	<option value="BOL">Bolivia, Plurinational State of</option>
	<option value="BES">Bonaire, Sint Eustatius and Saba</option>
	<option value="BIH">Bosnia and Herzegovina</option>
	<option value="BWA">Botswana</option>
	<option value="BVT">Bouvet Island</option>
	<option value="BRA">Brazil</option>
	<option value="IOT">British Indian Ocean Territory</option>
	<option value="BRN">Brunei Darussalam</option>
	<option value="BGR">Bulgaria</option>
	<option value="BFA">Burkina Faso</option>
	<option value="BDI">Burundi</option>
	<option value="KHM">Cambodia</option>
	<option value="CMR">Cameroon</option>
	<option value="CAN">Canada</option>
	<option value="CPV">Cape Verde</option>
	<option value="CYM">Cayman Islands</option>
	<option value="CAF">Central African Republic</option>
	<option value="TCD">Chad</option>
	<option value="CHL">Chile</option>
	<option value="CHN">China</option>
	<option value="CXR">Christmas Island</option>
	<option value="CCK">Cocos (Keeling) Islands</option>
	<option value="COL">Colombia</option>
	<option value="COM">Comoros</option>
	<option value="COG">Congo</option>
	<option value="COD">Congo, the Democratic Republic of the</option>
	<option value="COK">Cook Islands</option>
	<option value="CRI">Costa Rica</option>
	<option value="CIV">Côte d'Ivoire</option>
	<option value="HRV">Croatia</option>
	<option value="CUB">Cuba</option>
	<option value="CUW">Curaçao</option>
	<option value="CYP">Cyprus</option>
	<option value="CZE">Czech Republic</option>
	<option value="DNK">Denmark</option>
	<option value="DJI">Djibouti</option>
	<option value="DMA">Dominica</option>
	<option value="DOM">Dominican Republic</option>
	<option value="ECU">Ecuador</option>
	<option value="EGY">Egypt</option>
	<option value="SLV">El Salvador</option>
	<option value="GNQ">Equatorial Guinea</option>
	<option value="ERI">Eritrea</option>
	<option value="EST">Estonia</option>
	<option value="ETH">Ethiopia</option>
	<option value="FLK">Falkland Islands (Malvinas)</option>
	<option value="FRO">Faroe Islands</option>
	<option value="FJI">Fiji</option>
	<option value="FIN">Finland</option>
	<option value="FRA">France</option>
	<option value="GUF">French Guiana</option>
	<option value="PYF">French Polynesia</option>
	<option value="ATF">French Southern Territories</option>
	<option value="GAB">Gabon</option>
	<option value="GMB">Gambia</option>
	<option value="GEO">Georgia</option>
	<option value="DEU">Germany</option>
	<option value="GHA">Ghana</option>
	<option value="GIB">Gibraltar</option>
	<option value="GRC">Greece</option>
	<option value="GRL">Greenland</option>
	<option value="GRD">Grenada</option>
	<option value="GLP">Guadeloupe</option>
	<option value="GUM">Guam</option>
	<option value="GTM">Guatemala</option>
	<option value="GGY">Guernsey</option>
	<option value="GIN">Guinea</option>
	<option value="GNB">Guinea-Bissau</option>
	<option value="GUY">Guyana</option>
	<option value="HTI">Haiti</option>
	<option value="HMD">Heard Island and McDonald Islands</option>
	<option value="VAT">Holy See (Vatican City State)</option>
	<option value="HND">Honduras</option>
	<option value="HKG">Hong Kong</option>
	<option value="HUN">Hungary</option>
	<option value="ISL">Iceland</option>
	<option value="IND">India</option>
	<option value="IDN">Indonesia</option>
	<option value="IRN">Iran, Islamic Republic of</option>
	<option value="IRQ">Iraq</option>
	<option value="IRL">Ireland</option>
	<option value="IMN">Isle of Man</option>
	<option value="ISR">Israel</option>
	<option value="ITA">Italy</option>
	<option value="JAM">Jamaica</option>
	<option value="JPN">Japan</option>
	<option value="JEY">Jersey</option>
	<option value="JOR">Jordan</option>
	<option value="KAZ">Kazakhstan</option>
	<option value="KEN">Kenya</option>
	<option value="KIR">Kiribati</option>
	<option value="PRK">Korea, Democratic People's Republic of</option>
	<option value="KOR">Korea, Republic of</option>
	<option value="KWT">Kuwait</option>
	<option value="KGZ">Kyrgyzstan</option>
	<option value="LAO">Lao People's Democratic Republic</option>
	<option value="LVA">Latvia</option>
	<option value="LBN">Lebanon</option>
	<option value="LSO">Lesotho</option>
	<option value="LBR">Liberia</option>
	<option value="LBY">Libya</option>
	<option value="LIE">Liechtenstein</option>
	<option value="LTU">Lithuania</option>
	<option value="LUX">Luxembourg</option>
	<option value="MAC">Macao</option>
	<option value="MKD">Macedonia, the former Yugoslav Republic of</option>
	<option value="MDG">Madagascar</option>
	<option value="MWI">Malawi</option>
	<option value="MYS">Malaysia</option>
	<option value="MDV">Maldives</option>
	<option value="MLI">Mali</option>
	<option value="MLT">Malta</option>
	<option value="MHL">Marshall Islands</option>
	<option value="MTQ">Martinique</option>
	<option value="MRT">Mauritania</option>
	<option value="MUS">Mauritius</option>
	<option value="MYT">Mayotte</option>
	<option value="MEX">Mexico</option>
	<option value="FSM">Micronesia, Federated States of</option>
	<option value="MDA">Moldova, Republic of</option>
	<option value="MCO">Monaco</option>
	<option value="MNG">Mongolia</option>
	<option value="MNE">Montenegro</option>
	<option value="MSR">Montserrat</option>
	<option value="MAR">Morocco</option>
	<option value="MOZ">Mozambique</option>
	<option value="MMR">Myanmar</option>
	<option value="NAM">Namibia</option>
	<option value="NRU">Nauru</option>
	<option value="NPL">Nepal</option>
	<option value="NLD">Netherlands</option>
	<option value="NCL">New Caledonia</option>
	<option value="NZL">New Zealand</option>
	<option value="NIC">Nicaragua</option>
	<option value="NER">Niger</option>
	<option value="NGA">Nigeria</option>
	<option value="NIU">Niue</option>
	<option value="NFK">Norfolk Island</option>
	<option value="MNP">Northern Mariana Islands</option>
	<option value="NOR">Norway</option>
	<option value="OMN">Oman</option>
	<option value="PAK">Pakistan</option>
	<option value="PLW">Palau</option>
	<option value="PSE">Palestinian Territory, Occupied</option>
	<option value="PAN">Panama</option>
	<option value="PNG">Papua New Guinea</option>
	<option value="PRY">Paraguay</option>
	<option value="PER">Peru</option>
	<option value="PHL">Philippines</option>
	<option value="PCN">Pitcairn</option>
	<option value="POL">Poland</option>
	<option value="PRT">Portugal</option>
	<option value="PRI">Puerto Rico</option>
	<option value="QAT">Qatar</option>
	<option value="REU">Réunion</option>
	<option value="ROU">Romania</option>
	<option value="RUS">Russian Federation</option>
	<option value="RWA">Rwanda</option>
	<option value="BLM">Saint Barthélemy</option>
	<option value="SHN">Saint Helena, Ascension and Tristan da Cunha</option>
	<option value="KNA">Saint Kitts and Nevis</option>
	<option value="LCA">Saint Lucia</option>
	<option value="MAF">Saint Martin (French part)</option>
	<option value="SPM">Saint Pierre and Miquelon</option>
	<option value="VCT">Saint Vincent and the Grenadines</option>
	<option value="WSM">Samoa</option>
	<option value="SMR">San Marino</option>
	<option value="STP">Sao Tome and Principe</option>
	<option value="SAU">Saudi Arabia</option>
	<option value="SEN">Senegal</option>
	<option value="SRB">Serbia</option>
	<option value="SYC">Seychelles</option>
	<option value="SLE">Sierra Leone</option>
	<option value="SGP">Singapore</option>
	<option value="SXM">Sint Maarten (Dutch part)</option>
	<option value="SVK">Slovakia</option>
	<option value="SVN">Slovenia</option>
	<option value="SLB">Solomon Islands</option>
	<option value="SOM">Somalia</option>
	<option value="ZAF">South Africa</option>
	<option value="SGS">South Georgia and the South Sandwich Islands</option>
	<option value="SSD">South Sudan</option>
	<option value="ESP">Spain</option>
	<option value="LKA">Sri Lanka</option>
	<option value="SDN">Sudan</option>
	<option value="SUR">Suriname</option>
	<option value="SJM">Svalbard and Jan Mayen</option>
	<option value="SWZ">Swaziland</option>
	<option value="SWE">Sweden</option>
	<option value="CHE">Switzerland</option>
	<option value="SYR">Syrian Arab Republic</option>
	<option value="TWN">Taiwan, Province of China</option>
	<option value="TJK">Tajikistan</option>
	<option value="TZA">Tanzania, United Republic of</option>
	<option value="THA">Thailand</option>
	<option value="TLS">Timor-Leste</option>
	<option value="TGO">Togo</option>
	<option value="TKL">Tokelau</option>
	<option value="TON">Tonga</option>
	<option value="TTO">Trinidad and Tobago</option>
	<option value="TUN">Tunisia</option>
	<option value="TUR">Turkey</option>
	<option value="TKM">Turkmenistan</option>
	<option value="TCA">Turks and Caicos Islands</option>
	<option value="TUV">Tuvalu</option>
	<option value="UGA">Uganda</option>
	<option value="UKR">Ukraine</option>
	<option value="ARE">United Arab Emirates</option>
	<option value="GBR">United Kingdom</option>
	<option value="USA">United States</option>
	<option value="UMI">United States Minor Outlying Islands</option>
	<option value="URY">Uruguay</option>
	<option value="UZB">Uzbekistan</option>
	<option value="VUT">Vanuatu</option>
	<option value="VEN">Venezuela, Bolivarian Republic of</option>
	<option value="VNM">Viet Nam</option>
	<option value="VGB">Virgin Islands, British</option>
	<option value="VIR">Virgin Islands, U.S.</option>
	<option value="WLF">Wallis and Futuna</option>
	<option value="ESH">Western Sahara</option>
	<option value="YEM">Yemen</option>
	<option value="ZMB">Zambia</option>
	<option value="ZWE">Zimbabwe</option>
</select>
                                        </div>
                                            <br/>
                                            <div class="flex-container">   
                                                <label>{content.gradprebivalista}:</label>
                                                <input id={"gradprebivalista"+array} type={"text"} />
                                            </div>
                                            <br/>
                                            <div class="flex-container">   
                                                <label>{content.gradrodenja}:</label>
                                                <input id={"gradrodenja"+array} type={"text"} />
                                            </div>
                                            <br/>
                                            <div class="flex-container">   
                                                <label>{content.brojisprave}:</label>
                                                <input id={"brojisprave"+array} type={"text"} />
                                            </div>
                                            <br/>
                                            <div class="flex-container">   
                                                <label>{content.DocumentType}:</label>
                                                <select id={"tipisprave"+array}>
                                                    <option value="osobnaeu">{content.osobna}</option>
                                                    <option value="passport">{content.passport}</option>
                                                </select>
                                            </div>
                                            <br/>
                                            <div className="error">{error}</div>
                                            <Button onClick={() => checkin()}>Check-in</Button>

                                        </div>

                                    )
                                }
                            </div>
                                           
                                
                            <br/><br/><br/><br/><br/><br/><br/> <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                        </div>
                    </div>
                </div>

            </div>


            <div id="datanotneeded">
                <h2>{content.hvalanaunosu}</h2>
                <a className="" href={linksazetak}>{content.sazetak}</a>
                <br/><br/>
            </div>

            <Footer  language={props.language} />
        </div>
    )
}

export default Checkin;
import React from "react";
import axios from 'axios';
import IntranetHeader from "./intranetHeader";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Optional theme CSS
import { Button } from "react-bootstrap";

function Maillista() {

    const [mailinglista, setMailinglista] = React.useState([]);
    const [ime_osobe,setIme_osobe] = React.useState("");
    const [email,setEmail] = React.useState("");

    const colmunDefs = [
        {field: 'imeOsobe',
        filter: 'agTextColumnFilter',
        filterOptions: ['contains', 'notContains'],
        sortable: true
        },
        {field: 'email',
        sortable: true,
        filter: 'agTextColumnFilter',
        filterOptions: ['contains', 'notContains']}
    ];
    const gridStyle = React.useMemo(() => ({ height: '400px', width: '600px' }), []);
    const gridRef = React.useRef();

    React.useEffect(() => {
        fetchMailLista();
    },[])

    function fetchMailLista() {
        
        axios.get("/mailinglist")
        .then(d => d.data)
        .then(list => setMailinglista(list))
    }

    function changeImeOsobe(event) {
        const value = event.target.value;
        setIme_osobe(value);
    }

    function changeEmail(event) {
        const value = event.target.value;
        setEmail(value);
    }

    function izbrisi() {

        let rows =  gridRef.current.api.getSelectedRows();
        for(let i = 0; i < rows.length;i++) {

            let id = rows[i].idOsobe;

            
        axios.delete('/mailinglist',{
            data : {
                idOsobe: id,
            }       
        })
        .then(res => res.data)
        .then(data => setMailinglista(data))
            
        }


    }

    function submit(e) {
        e.preventDefault()

        axios.post('/mailinglist',{
            ime_osobe: ime_osobe,
            email: email
        })
        .then(res => res.data)
        .then(data => setMailinglista(data))

        setIme_osobe("")
        setEmail("")
    }
    
    return <div>
       <IntranetHeader/>
       <br/><br/><br/><br/><br/><br/><br/>


       <h2>Uredi mailing listu</h2>
       <br/>
      


       <div className="centerTable">
            <div>
            <Button onClick={() => izbrisi()}>Izbrisi odabrane retke</Button>
            <br/>
            <br/>
           <div className="ag-theme-alpine" style={gridStyle}>
                <AgGridReact ref={gridRef} rowSelection='multiple' editable={true} pagination={true} paginationAutoPageSize={true} rowData={mailinglista} columnDefs={colmunDefs}></AgGridReact>
            </div>
            <br/>
            <div>
                    <td>
                        <input type="text"  onChange={changeImeOsobe} value={ime_osobe}  />
                    </td>
                    <td>
                        <input type="email" onChange={changeEmail} value={email} />
                    </td>
                    <td>
                        <Button onClick={submit}>
                            Unesi novi Email
                        </Button>
                    </td>
                </div>
            </div>
        </div>

    </div>  
}

export default Maillista
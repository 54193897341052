import React from "react";
import "./css/bootstrap.min.css"
import "./css/style.css"
import "./css/responsive.css"
import "./css/custom.css"
import "./css/mainpage.css"
import Header from "./header";



import Footer from "./footer";
import logo from './static/logo.webp'; // with import
import Rooms from "./Rooms";
import okerkada from "./static/korana1.jpg"
import sivasoba from "./static/una2.webp"
import okerkrevet from "./static/zora4.jpg"
import kuh1 from "./static/kuh1.webp"
import kuh2 from "./static/kuh2.webp"
import kuh3 from "./static/kuh3.webp"
import kuh4 from "./static/kuh4.webp"
import vani1 from "./static/vani1.webp"
import vani2 from "./static/vani2.webp"
import vani3 from "./static/vani3.webp"
import vani4 from "./static/vani4.webp"
import staircase from "./static/staircase.webp"



function Mainpage(props) {

    
    let content = {
        English: {
            home:"home",
            istrazite:"Explore Plitvice Lakes",
            rezervacija:"Reserve your room",
            contact:"Contact",
			welcometo:"Welcome to",
			kratkiopis:"Experience Luxury stay at the world-renowned National Park Plitvice Lakes ",
			malaprica:"Our Story",
			einsteinqoute:"Look deep into nature, and then you will understand everything better.",
			istraziteSobe:"Explore the rooms",
			sobepodnaslov:"We offer you wide range of rooms, from two bed deluxe to four bed luxury",
			sve:"All",
			reviews:"Customer Reviews",
			reviewspodnaslov:"See our excellence for yourself through the comments of our satisfied guests",
			galerijaSlika:"Gallery",
			galerijaSlikapodnaslov:"Explore the surroundings and additional activities of the Villa ",
			lokacija:"Location",
			kontaktbroj:"Contact number",
			gostugodini:"Guest in ",
			review1:"Beautiful rooms, sauna is great and breakfast was very tasty",
			review2:"Comfy stay and great host",
			review3:"Great location and nice rooms",
			prviparagraf:"Located in the heart of National Park Plitvice Lakes, Villa Pearl of Plitvice exudes adventure and relaxation. Each room, inspired by a Croatian woman's name,evokes the story and past of this enigmatic park",
			drugiparagraf:"Villa is located only 300m from the entrance to the well-established trails of the Plitvice Lakes. It provides an excellent breakfast that is included in every room rate, a hot tub and sauna",
			popust:"10% discount for all 2024. reservations, use code : summer2024"
		},
        German: {
			welcometo:"Herzlich willkommen",
			kratkiopis:"Erleben Sie einen luxuriösen Urlaub im weltberühmten Nationalpark Plitvička Jezera",
			malaprica:"Unsere Geschichte",
			einsteinqoute:"Schau tief in die Natur, dann verstehst du alles besser.",
			istraziteSobe:"Erkunden Sie das Zimmerangebot",
			sobepodnaslov:"Unser Angebot umfasst eine große Auswahl an Zimmern : deluxe, superior i luxury",
			sve:"Alles",
			reviews:"Gastkommentare",
			reviewspodnaslov:"Überzeugen Sie sich selbst von unserer Exzellenz durch die Kommentare unserer zufriedenen Gäste",
			galerijaSlika:"Galerie",
			galerijaSlikapodnaslov:"Erkunden Sie die Umgebung und zusätzliche Aktivitäten der Villa",
			lokacija:"Lage",
			kontaktbroj:"Kontakt Nummer",
			gostugodini:"Gast ein ",
			review1:"Schöne Zimmer, die Sauna ist toll und das Frühstück war lecker",
			review2:"Angenehmer Aufenthalt in der Villa und der Gastgeber ist großartig",
			review3:"Tolle Lage und schöne Zimmer",
			prviparagraf:"Die Villa Pearl of Plitvice liegt im Herzen des Nationalparks Plitvicer Seen und strahlt Abenteuer und Entspannung aus. Jedes Zimmer, inspiriert vom Namen einer kroatischen Frau, erinnert an die Geschichte und Vergangenheit dieses rätselhaften Klimas.",
			drugiparagraf:"Die Villa befindet sich nur 300 m vom Eingang zu den gut etablierten Wanderwegen der Plitvicer Seen entfernt. Es bietet ein ausgezeichnetes Frühstück, das in jedem Zimmer inbegriffen ist, ein heißes Bad in der Wanne und eine Sauna",
			popust:"10% Rabatt für alle 2024. Reservierungen, verwenden Sie den Code: summer2024"

        },
        Croatian: {
        home:"Početna",
        istrazite:"Istražite Plitvička Jezera",
        rezervacija:"Rezervirajte sobu",
        contact:"Kontakt",
		welcometo:"Dobrodošli",
		kratkiopis:"Iskusite luksuzni odmor u svjetski poznatom Nacionalnom parku Plitvička Jezera",
		malaprica:"Naša Priča",
		einsteinqoute:"Pogledajte duboko u prirodu, onda ćete sve razumjeti bolje.",
		istraziteSobe:"Istražite ponudu soba",
		sobepodnaslov:"U našoj ponudi nalaze se veliki izbor soba : deluxe, superior i luxury",
        sve:"Sve",
		reviews:"Komentari gostiju",
		reviewspodnaslov:"Uvjerite se u našu izvrsnost kroz komentare naših zadovoljnih gostiju",
		galerijaSlika:"Galerija",
		galerijaSlikapodnaslov:"Istražite okruženje i dodatne aktivnosti Ville",
		lokacija:"Lokacija",
		kontaktbroj:"Kontakt broj",
		gostugodini:"Gost u ",
		review1:"Predivne sobe, sauna je super i doručak je bio ukusan",
		review2:"Ugodan boravak u villi, a domaćin je super",
		review3:"Odlična lokacija i lijepe sobe",
		prviparagraf:"Smještena u samom srcu nacionalnog parka Plitvička jezera, Villa Pearl of Plitvice odiše avanturom i odmorom. Svaka soba, inspirirana jednim hrvatskim imenom žene, dočarava priču i prošlost ovog zagonetnog podneblja.",
		drugiparagraf:"Villa se nalazi samo 300m od ulaza u uhodane staze Plitvičkih jezera. Pruža odličan dorućak koji je uključen u svaku sobu, vruću kupku u kadi i saunu",
		popust:"10% popusta za sve rezervacije za 2024., koristite kod : summer2024"

	}
    }

    if(props.language === "English") {
        content = content.English
    } else if(props.language === "Croatian") {
        content = content.Croatian
    } else {
        content = content.German
    }

    return( 
        <body>
            
	<Header language={props.language}
            handleSetLanguage={props.handleSetLanguage}
			
    />

	
	<div id="slides" class="cover-slides">
		<ul class="slides-container">
			<li class="text-center">
				<img src={sivasoba} alt=""/>
	 			<div class="container">
					<div class="row">
						<div class="col-md-12">
							<h1 class="m-b-20"><strong>{content.welcometo} <br/> Boutique Villa Pearl of Plitvice </strong></h1>
							<p class="m-b-40">{content.kratkiopis}</p>
							<p><a class="btn btn-lg btn-circle btn-outline-new-white" href="/rezervacija">{content.rezervacija}</a></p>
						</div>
					</div>
				</div>
			</li>
			<li class="text-center">
				<img src={okerkrevet} alt="" />
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<h1 class="m-b-20"><strong>{content.welcometo} <br/> Boutique Villa Pearl of Plitvice</strong></h1>
							<p class="m-b-40">{content.kratkiopis}</p>
							<p><a class="btn btn-lg btn-circle btn-outline-new-white" href="/rezervacija">{content.rezervacija}</a></p>
						</div>
					</div>
				</div>
			</li>
			<li class="text-center">
				<img src={okerkada} alt="" />
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<h1 class="m-b-20"><strong>{content.welcometo} <br/> Boutique Villa Pearl of Plitvice</strong></h1>
							<p class="m-b-40">{content.kratkiopis}</p>
							<p><a class="btn btn-lg btn-circle btn-outline-new-white" href="/rezervacija">{content.rezervacija}</a></p>
						</div>
					</div>
				</div>
			</li>
		</ul>
		<div class="slides-navigation">
			<a href="#" class="next"><i class="fa fa-angle-right" aria-hidden="true"></i></a>
			<a href="#" class="prev"><i class="fa fa-angle-left" aria-hidden="true"></i></a>
		</div>
	</div>
	<div className="popustbanner">
		{content.popust}
	</div>
	
	<div class="about-section-box">
		<div class="container">
			<div class="row">
				<div class="col-lg-6 col-md-6 col-sm-12">
					<img src={logo} alt="" class="img-fluid" />
				</div>
				<div class="col-lg-6 col-md-6 col-sm-12 text-center">
					<div class="inner-column">
						<h1>{content.welcometo} <span> Boutique Villa Pearl of Plitvice</span></h1>
						<h4>{content.malaprica}</h4>
						<p>{content.prviparagraf} </p>
						<p>{content.drugiparagraf}</p>
						<a class="btn btn-lg btn-circle btn-outline-new-white" href="/rezervacija">Reservation</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div class="qt-box qt-background">
		<div class="container">
			<div class="row">
				<div class="col-md-8 ml-auto mr-auto text-left">
					<p class="lead ">
					  "{content.einsteinqoute}"
					</p>
					<span class="lead">Albert Einstein</span>
				</div>
			</div>
		</div>
	</div>
	
	<Rooms language={props.language} />
	
	<div class="gallery-box">
		<div class="container-fluid">
			<div class="row">
				<div class="col-lg-12">
					<div class="heading-title text-center">
						<h2>{content.galerijaSlika}</h2>
						<p>{content.galerijaSlikapodnaslov}</p>
					</div>
				</div>
			</div>
			<div class="tz-gallery">
				<div class="row">
					<div class="col-sm-12 col-md-4 col-lg-4">
						<a class="lightbox" href={kuh1}>
							<img class="img-fluid" src={kuh1} alt="Gallery Images" />
						</a>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-4">
						<a class="lightbox" href={kuh2}>
							<img class="img-fluid" src={kuh2} alt="Gallery Images" />
						</a>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-4">
						<a class="lightbox" href={kuh4}>
							<img class="img-fluid" src={kuh4} alt="Gallery Images" />
						</a>
					</div>
					<div class="col-sm-12 col-md-4 col-lg-4">
						<a class="lightbox" href={vani3}>
							<img class="img-fluid" src={vani3} alt="Gallery Images" />
						</a>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-4">
						<a class="lightbox" href={vani1}>
							<img class="img-fluid" src={vani1} alt="Gallery Images" />
						</a>
					</div> 
					<div class="col-sm-6 col-md-4 col-lg-4">
						<a class="lightbox" href={vani2}>
							<img class="img-fluid" src={vani2} alt="Gallery Images" />
						</a>
					</div>

					<div class="col-sm-6 col-md-4 col-lg-4">
						<a class="lightbox" href={kuh3}>
							<img class="img-fluid" src={kuh3} alt="Gallery Images" />
						</a>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-4">
						<a class="lightbox" href={vani4}>
							<img class="img-fluid" src={vani4} alt="Gallery Images" />
						</a>
					</div>
					<div class="col-sm-6 col-md-4 col-lg-4">
						<a class="lightbox" href={staircase}>
							<img class="img-fluid" src={staircase} alt="Gallery Images" />
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div class="customer-reviews-box">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="heading-title text-center">
						<h2>{content.reviews}</h2>
						<p>{content.reviewspodnaslov}</p>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-8 mr-auto ml-auto text-center">
					<div id="reviews" class="carousel slide" data-ride="carousel">
						<div class="carousel-inner mt-4">
							<div class="carousel-item text-center active">
								<div class="img-box p-1 border rounded-circle m-auto">
									<img class="d-block w-100 rounded-circle" src={logo} alt="" />
								</div>
								<h5 class="mt-4 mb-0"><strong class="text-warning text-uppercase">Anja Bralić</strong></h5>
								<h6 class="text-dark m-0">{content.gostugodini} 2022.</h6>
								<p class="m-0 pt-3">{content.review1}</p>
							</div>
							<div class="carousel-item text-center">
								<div class="img-box p-1 border rounded-circle m-auto">
									<img class="d-block w-100 rounded-circle" src={logo} alt="" />
								</div>
								<h5 class="mt-4 mb-0"><strong class="text-warning text-uppercase">Nino Šatrak</strong></h5>
								<h6 class="text-dark m-0">{content.gostugodini} 2022.</h6>
								<p class="m-0 pt-3">{content.review2}</p>
							</div>
							<div class="carousel-item text-center">
								<div class="img-box p-1 border rounded-circle m-auto">
									<img class="d-block w-100 rounded-circle" src={logo} alt="" />
								</div>
								<h5 class="mt-4 mb-0"><strong class="text-warning text-uppercase">Danica Srnec</strong></h5>
								<h6 class="text-dark m-0">{content.gostugodini} 2022.</h6>
								<p class="m-0 pt-3">{content.review3}</p>
							</div>
						</div>
						<a class="carousel-control-prev" href="#reviews" role="button" data-slide="prev">
							<i class="fa fa-angle-left" aria-hidden="true"></i>
							<span class="sr-only">Previous</span>
						</a>
						<a class="carousel-control-next" href="#reviews" role="button" data-slide="next">
							<i class="fa fa-angle-right" aria-hidden="true"></i>
							<span class="sr-only">Next</span>
						</a>
                    </div>
				</div>
			</div>
		</div>
	</div>
	
	<div class="contact-imfo-box">
		<div class="container">
			<div class="row">
				<div class="col-md-4">
					<i class="fa fa-volume-control-phone"></i>
					<div class="overflow-hidden">
						<h4>{content.kontaktbroj}</h4>
						<p class="lead">
							+385 99-813-3790
						</p>
					</div>
				</div>
				<div class="col-md-4">
					<i class="fa fa-envelope"></i>
					<div class="overflow-hidden">
						<h4>Email</h4>
						<p class="lead">
							info@pearlofplitvice.com
						</p>
					</div>
				</div>
				<div class="col-md-4">
					<i class="fa fa-map-marker"></i>
					<div class="overflow-hidden">
						<h4>{content.lokacija}</h4>
						<p class="lead">
							plitvica selo 70a, Croatia
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<Footer  language={props.language}
            handleSetLanguage={props.handleSetLanguage}
    />
	

	
	
</body>
    )
}

export default Mainpage
import React from 'react'
import './css/header.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import logo from './static/logo.webp'; // with import


function Header(props) {

    let content = {
        English: {
            home:"Home",
            istrazite:"Explore Plitvice Lakes",
            rezervacija:"Reserve your room",
            contact:"Contact",
            zaosoblje:"For staff"
        },
        German: {
            home:"Heim",
            rezervacija:"Zimmer reservieren",
            zaosoblje:"für Personal"
        },
        Croatian: {
           home:"Početna",
           istrazite:"Istražite Plitvička Jezera",
           rezervacija:"Rezervirajte sobu",
           contact:"Kontakt",
           zaosoblje:"Za osoblje"
        }
    }

    if(props.language === "English") {
        content = content.English
     } else if(props.language === "Croatian") {
        content = content.Croatian
     } else {
         content = content.German
     }
    

    return (        
        <header class="top-navbar">
		<nav class="navbar navbar-expand-lg navbar-light bg-light">
			<div class="container">
				<a class="navbar-brand" href="/">
					<img width={"100px"} src={logo} alt="" />
				</a>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbars-rs-food" aria-controls="navbars-rs-food" aria-expanded="false" aria-label="Toggle navigation">
				  <span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbars-rs-food">
					<ul class="navbar-nav ml-auto">
						<li class="nav-item"><a class="nav-link" href="/">{content.home}</a></li>
						<li class="nav-item"><a class="nav-link" href="/rezervacija">{content.rezervacija}</a></li>
                        <li class="nav-item"><a class="nav-link" href="/admin">{content.zaosoblje}</a></li>
					</ul>
                    <div className='language-select'>
                        <select
                            className="custom-select"
                            value={props.language}
                            onChange={e => props.handleSetLanguage(e.target.value)}
                        >
                            <option value="English">English</option>
                            <option value="German">German</option>
                            <option value="Croatian">Croatian</option>
                        </select>
                    </div>
				</div>
			</div>
		</nav>
	</header>
    )
}

export default Header

import React, { useEffect, useState } from "react";
import IntranetHeader from "./intranetHeader";
import { Button } from "react-bootstrap";
import axios from "axios";



function Radnici() {
    const [tab,setTab] = useState("RadniSati")

    const [rad,setRad] = useState([])

    const [uredi,seturedi] = useState("")
    const [rad_from,setrad_from] = useState("")
    const [rad_to,setrad_to] = useState("")
    const [odobreno,setodobreno] = useState(true)
    const [obrisano,setobrisano] = useState(false)


    const [mjesec,setmjesec] = useState(new Date().getMonth() + 1)

    const [selectedDate,setSelectedDate] = useState(new Date().toISOString().slice(0, 10))
    function getRad() {
        axios.get("/rad/admin?datum="+selectedDate)
        .then(res => res.data)
        .then(data => {
            for(let r of data) {
                console.log(r)
            }
            setRad(data)
        })
    }

    useEffect(() => {
        setrad_from(uredi.radfrom)
        setrad_to(uredi.rad_to)
        setodobreno(uredi.odobreno)
        setobrisano(uredi.izbrisano)
    },[uredi])

    useEffect(() => {
        getRad()
    },[selectedDate])

    function spremi() {
        console.log(uredi.idRad)
        console.log(rad_from)
        console.log(rad_to)
        console.log(odobreno)
        console.log(obrisano)
        let rf = rad_from;
        let rt = rad_to;
        if(rad_from.length <= 5) {
            rf = rf + ":00"
        }
        if(rad_to.length <= 5) {
            rt = rt + ":00"
        }
        axios.get("/radrad?od="+rf+"&to="+rt+"&idRad="+uredi.idRad+"&odobreno="+odobreno+"&obrisano="+obrisano)
        .then(res => getRad())

    }

    function posaljireport() {
        axios.get("/rad/report?mjesec="+mjesec)
    }

    return (        
        <div>
        <IntranetHeader/>
        <br/>        
        <br/>
        <br/>
        <br/>
        <br/>
        <div>
            <Button className="btn-primary m-2" onClick={() => setTab("RadniSati")}>Radni sati</Button>
            <Button className="btn-secondary m-2" onClick={() => setTab("Izvjestaji")}>Izvjestaj place</Button>
        </div>

        <div>
            {
                tab === "RadniSati" && 
                <div>
                    <div className="naslov"> Radni Sati </div>
                    <input value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} type="date"></input>
                    <br/><br/>
                    {
                        rad.length === 0 &&
                        <div className="text-info"> Nema podnešenih radnih sati za ovaj dan</div>
                    }
                    {
                        rad.length !== 0 &&
                        <div> 
                            {
                                rad.map(r => <div key={r.idRad}>{r.radfrom}-{r.rad_to} <br/> {r.radnik.ime} {r.radnik.prezime} <br/> {r.razlog}
                                {r.prekovremeno ? <div className="text-warning">Prekovremeno</div> : ""} <br/>
                                {r.odobreno ? <div className="text-success">Odobreno</div> : <div className="text-danger">Nije još odobreno</div>} <br/>
                                {r.izbrisano ? <div className="text-danger">Obrisano</div> : ""} <br/>
                                <Button onClick={() => {seturedi(r)}} data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn-info">Uredi</Button>
                                <hr/>

                                 </div>)
                            }
                        </div>
                    }

                    <div class="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="exampleModalLabel">Uredi</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                

                                <input type="time" value={rad_from} step="1" onChange={(e) => setrad_from(e.target.value)} /> -
                                <input type="time" value={rad_to} step="1" onChange={(e) => setrad_to(e.target.value)}/>
                                <div class="form-check form-switch">
                                    <label class="form-check-label" for="flexSwitchCheckChecked">Odobreno? &nbsp;</label>
                                    <input type="checkbox"  id="flexSwitchCheckChecked" checked={odobreno} onChange={() => setodobreno(!odobreno)}/>
                                </div>
                                <div class="form-check form-switch">
                                    <label class="form-check-label" for="flexSwitchCheckChecked">Obrisano? &nbsp;</label>
                                    <input type="checkbox" id="flexSwitchCheckChecked" checked={obrisano} onChange={() => setobrisano(!obrisano)}/>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary mx-5" data-bs-dismiss="modal">Close</button>
                                <button type="button" onClick={() => spremi()} data-bs-dismiss="modal" class="btn btn-primary mx-2">Save changes</button>
                            </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
            {
                tab === "Izvjestaji" &&
                <div>
                    <div className="naslov"> Slanje izvjestaja na mail </div>
                    <input className="p-2" type="number" value={mjesec} onChange={(e) => {
                        if(e.target.value < 1 ) {
                            setmjesec(1)
                        } else if(e.target.value > 12) {
                            setmjesec(12)
                        } else {
                            setmjesec(e.target.value)
                        }
                    }  }/> mjesec
                    <br/><br/>
                    <Button onClick={() => posaljireport()}>Pošalji izvjestaj za odabrani mjesec na mail.</Button>
                </div>
            }
        </div>

        </div>
    )
}

export default Radnici
import React from "react";
import axios from 'axios';
import AuthHandler from "./AuthHandler";
import logo from './images/pearllogo.jpg'; // with import
import { Button } from "react-bootstrap";


function IntranetHeader() {


    

    function logout() {
        AuthHandler.logout();
        window.location.replace("/");
    }

    return (        
        <header class="top-navbar">
		<nav class="navbar navbar-expand-lg navbar-light bg-light">
			<div class="container">
				<a class="navbar-brand" href="/">
					<img src={logo} alt="" />
				</a>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbars-rs-food" aria-controls="navbars-rs-food" aria-expanded="false" aria-label="Toggle navigation">
				  <span class="navbar-toggler-icon"></span>
				</button>
				<div class="collapse navbar-collapse" id="navbars-rs-food">
					<ul class="navbar-nav ml-auto">
						<li class="nav-item"><a class="nav-link" href="/">Početna</a></li>
						<li class="nav-item"><a class="nav-link" href="/admin">Intranet početna</a></li>
						<li class="nav-item"><a class="nav-link" href="/radnici">Upraljanje plaćama</a></li>
						<li class="nav-item"><a class="nav-link" href="/uredi">Upravljanje podacima</a></li>
						<li class="nav-item"><a class="nav-link" href="/izvjesca">Izvještaji</a></li>
						<li class="nav-item"><a class="nav-link" href="/logs">Logs</a></li>
                        <li class="nav-item"><Button onClick={() => logout()} class="nav-link" href="">logout</Button></li>
					</ul>
				</div>
			</div>
		</nav>
	</header>
    )
}

export default IntranetHeader
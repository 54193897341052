import IntranetHeader from "./intranetHeader"
import React from "react";
import axios from 'axios';
import { Button } from "react-bootstrap";
import './css/izvjestaji.css';


import "./css/bootstrap.min.css"
import "./css/style.css"
import "./css/responsive.css"
import "./css/custom.css"


function Izvjestaji() {

    const [godina,setGodina] = React.useState(2023);

    const [izvjestaji,setIzvjestaji] = React.useState([]);
    const [izvjestajisobe,setIzvjestajisobe] = React.useState([]);


    const [pocetak,setPocetak] = React.useState(Date.now)
    const [kraj,setKraj] = React.useState(Date.now)
    const [prodajaStranica,setProdajaStranica] = React.useState([])
    const [prodajaStranicasum,setProdajaStranicasum] = React.useState([])

    const [start,setstart] = React.useState(Date.now)
    const [end,setend] = React.useState(Date.now)
    const [novi,setNovi] = React.useState("")

    function ProdajaIzvjestaj() {
        console.log(pocetak + " " + kraj)
        axios.get('/api/prodajastranica?pocetak='+pocetak+'&kraj='+kraj)
        .then(res => {
            console.log(res) 
            return res.data})
        .then(data => {
            console.log(data)
            setProdajaStranica(data)
        })

    }

    function ProdajaIzvjestajsum() {
        console.log(pocetak + " " + kraj)
        axios.get('/api/prodajastranicasum?pocetak='+pocetak+'&kraj='+kraj)
        .then(res => {
            console.log(res) 
            return res.data})
        .then(data => {
            console.log(data)
            setProdajaStranicasum(data)
        })
    }

    function ProdajaIzvjestajNovi() {
        axios.get('/api/novi-izvjestaj?pocetak='+start+'&kraj='+end)
        .then(res => {
            console.log(res) 
            return res.data})
        .then(data => {
            console.log(data)
            setNovi(data)
        })
    }

   
    async function dohvati() {

         dohvatiSobe()

         await new Promise(r => setTimeout(r, 2000));


       axios.get('/izvjestaj?godina='+godina)
        .then((res) => res.data)
        .then(data => {
            let ar = []
            for(let i = 1; i <= 12; i++ ){
                ar.push(data[i])             
            }
            console.log(ar)
            setIzvjestaji(ar)
        })


    }

    function dohvatiSobe() {
        axios.get('/izvjestajsobe?godina='+godina)
        .then((res) => res.data)
        .then(data => {
            let ar = []
            for(let i = 1; i <= 12; i++ ){
                ar.push(data[i])             
            }
            console.log(ar)
            setIzvjestajisobe(ar)
        })
    }
    

    function godinachanged(event) {
        setGodina(event.target.value)
    }


    return (
        <div>
            <IntranetHeader/>
            <br/><br/><br/><br/><br/><br/><br/><br/>
            <h1 className="underline">Izvještaji</h1>
            <div className="centeredtable">
                <h3 className="underline">Prodaja na stranici od datuma pocetka do datuma kraja uključivo</h3>
                <label>pocetak : &nbsp;</label>
                <input onChange={(event) => setPocetak(event.target.value)} value={pocetak} type="date"/> &nbsp;
                <label> kraj : &nbsp;</label>
                <input onChange={(event) => setKraj(event.target.value)} value={kraj} type="date"/> &nbsp;
                <Button onClick={() =>  {
                    ProdajaIzvjestajsum();
                    ProdajaIzvjestaj();
                }
                }>Pregledaj </Button>
                <br/>
                <br/>

                <table className="centr">
                <th className="flex-center">
                    <td>Id_rezervacija</td>
                    <td>Odlazak</td>
                    <td>Noc</td>
                    <td>Cijena</td>
                    <td>Soba</td>
                    <td>Taxa</td>
                    <td>Neto+PDV</td>
                    <td>PDV</td>
                </th>
                <hr/>
                {
                    prodajaStranica.map(rez => <tr className="flex-center" key={rez.id_rez}>

                    <td>{rez.id_rez}</td>
                    <td>{rez.odlazak}</td>
                    <td>{rez.br_noci}</td>
                    <td>{rez.cijena}</td>
                    <td>{rez.ime_sobe}</td>
                    <td>{rez.pristrojba}</td>
                    <td>{rez.neto}</td>
                    <td>{rez.pdv}</td>

                        
                    </tr>)
                }
                <hr/>
                        <tr className="flex-center">
                            <td>XXXXXXXXXXX</td> 
                            <td>XXXXXXXXXXX</td> 
                            <td>{prodajaStranicasum.br_noci}</td> 
                            <td>{prodajaStranicasum.cijena} €</td> 
                            <td>XXXXX</td> 
                            <td>{prodajaStranicasum.pristrojba} €</td> 
                            <td>{prodajaStranicasum.neto} €</td> 
                            <td>{prodajaStranicasum.pdv} €</td> 

                        </tr>
                </table>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>

            </div>
            <div className="centeredtable">
                <h3 className="underline">Novi izvjestaj - oba datuma ukljucivo</h3>
                <label>pocetak : &nbsp;</label>
                <input onChange={(event) => setstart(event.target.value)} value={start} type="date"/> &nbsp;
                <label> kraj : &nbsp;</label>
                <input onChange={(event) => setend(event.target.value)} value={end} type="date"/> &nbsp;
                <Button onClick={() =>  {
                    ProdajaIzvjestajNovi()
                }
                }>Pregledaj </Button>
                <br/>
                <br/>

                <table className="centr">
                <th className="flex-center">
                    <td>brojnocenja</td>
                    <td>brojgostiju</td>
                    <td>promet</td>
                    <td>bookingrezervacija</td>
                    <td>popunjenost</td>
                </th>
                <hr/>
                <tr className="flex-center">
                    <td>{novi.brojnocenja}</td>
                    <td>{novi.brojgostiju}</td>
                    <td>{novi.promet}</td>
                    <td>{novi.bookingrezervacija}</td>
                    <td>{novi.popunjenost}</td>
                    </tr>
                </table>
                <br/>
                <br/>
            </div>

            <h3 className="underline">Sveukupni izvjestaj</h3>
            <h4>Odaberi godinu : </h4>
            <input type="number" min="2022" max="2099" step="1" value={godina} onChange={godinachanged} />
            <br/>
            <br/>
            <Button onClick={() => dohvati()}>Pregledaj ukupno</Button>
            <br/><br/>
            <div>
                {
                    izvjestaji.map(izvjestaj => izvjestaj != undefined && <div class="jumbotron jumbotron-fluid">
                    <div class="container">
                        <h1 class="display-5">{izvjestaji.indexOf(izvjestaj) + 1}. mjesec</h1>
                        <div className="centeredtable">

                            <div className="row">

                                <div className="col text-left">
                                &nbsp; Prosjecan broj ljudi po noci:
                                </div>

                                <div className="col text-center">
                                    {izvjestaj.prosjecan_broj_ljudi}
                                </div>

                            </div>  
                            <div className="row">

                                <div className="col text-left">
                                   &nbsp; Ukupan broj ugošćenih ljudi:
                                </div>
                                
                                <div className="col text-center">
                                    {izvjestaj.ukupnibrojljudi}
                                </div>

                            </div>
                            <div className="row">

                                <div className="col text-left">
                                &nbsp; Broj rezervacija booking ili expedia:
                                </div>

                                <div className="col text-center">
                                    {izvjestaj.postotak_exp_booking}
                                </div>

                            </div>
                            <div className="row">

                                <div className="col text-left">
                                &nbsp; Prosječan broj nocenja:
                                </div>

                                <div className="col text-center">
                                    {izvjestaj.prosjecan_broj_nocenja}
                                </div>

                            </div>
                            <div className="row">

                                <div className="col text-left">
                                &nbsp; Promet poslije OTA fee-ova:
                                </div>

                                <div className="col text-center">
                                    {izvjestaj.promet_neto}
                                </div>

                            </div>
                            <div className="row">

                                <div className="col text-left">
                                &nbsp; Ukupno ostvarenih nočenja : 
                                </div>

                                <div className="col text-center">
                                    {izvjestaj.ukupno_nocenja}
                                </div>

                            </div>
                            <div className="row">

                                <div className="col text-left">
                                   &nbsp; Postotak popunjenost:
                                </div>
                                
                                <div className="col text-center">
                                    {izvjestaj.postotak_popunjenost} 
                                </div>

                            </div>
                            <div className="row">

                                <div className="col text-left">
                                   &nbsp; Avg neto cijena poslije fee-ova:
                                </div>
                                
                                <div className="col text-center">
                                    {izvjestaj.avgnetocijena} 
                                </div>

                            </div>
                            
                            
                        </div>
                        <div className="centeredtable">
                        {izvjestajisobe[izvjestaji.indexOf(izvjestaj)].map( izvjestajsoba => <div className="centeredtable">
                            

                            <div className="row">

                            <div className="col text-left">
                            &nbsp; Soba:
                            </div>

                            <div className="col text-center">
                                {izvjestajsoba.imeSobe}
                            </div>

                            </div>  

                            <div className="row">

                            <div className="col text-left">
                            &nbsp; Prosjecan broj ljudi po noci:
                            </div>

                            <div className="col text-center">
                                {izvjestajsoba.prosjecan_broj_ljudi}
                            </div>

                            </div>  
                            <div className="row">

                            <div className="col text-left">
                            &nbsp; Ukupan broj ugošćenih ljudi:
                            </div>

                            <div className="col text-center">
                                {izvjestajsoba.ukupnibrojljudi}
                            </div>

                            </div>
                            <div className="row">

                            <div className="col text-left">
                            &nbsp; Broj rezervacija booking ili expedia:
                            </div>

                            <div className="col text-center">
                                {izvjestajsoba.postotak_exp_booking}
                            </div>

                            </div>
                            <div className="row">

                            <div className="col text-left">
                            &nbsp; Prosječan broj nocenja:
                            </div>

                            <div className="col text-center">
                                {izvjestajsoba.prosjecan_broj_nocenja}
                            </div>

                            </div>
                            <div className="row">

                            <div className="col text-left">
                            &nbsp; Promet poslije OTA fee-ova:
                            </div>

                            <div className="col text-center">
                                {izvjestajsoba.promet_neto}
                            </div>

                            </div>
                            <div className="row">

                            <div className="col text-left">
                            &nbsp; Ukupno ostvarenih nočenja : 
                            </div>

                            <div className="col text-center">
                                {izvjestajsoba.ukupno_nocenja}
                            </div>

                            </div>
                            <div className="row">

                            <div className="col text-left">
                            &nbsp; Postotak popunjenost:
                            </div>

                            <div className="col text-center">
                                {izvjestajsoba.postotak_popunjenost} 
                            </div>

                            </div>
                            <div className="row">

                            <div className="col text-left">
                            &nbsp; Avg neto cijena poslije fee-ova:
                            </div>

                            <div className="col text-center">
                                {izvjestajsoba.avgnetocijena} 
                            </div>

                            </div>
                            <div className="row">

                            <div className="col text-left">
                            &nbsp; Avg neto cijena poslije fee-ova za sve 2 sobne rezervacije:
                            </div>

                            <div className="col text-center">
                                {izvjestajsoba.avgnetocijena2sobe} 
                            </div>

                            </div>
                            
                        </div>) 
                        }
                        </div>
                    </div>
                </div> )
                }
            </div>


        </div>
    )
}

export default Izvjestaji
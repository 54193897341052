import React, { useEffect, useState } from "react";
import axios from 'axios';
import IntranetHeader from "./intranetHeader";



function Logs() {

    const[logKupnja,setLogKupnja] = useState([])
    const[logDostupnost,setLogDostupnost] = useState([])

    function getLogKupnja() {
        axios.get('getLogKupnja')
        .then(res => res.data)
        .then(data => {
            setLogKupnja(data)
        })
    }

    function getLogDostupnost() {
        axios.get('getLogDostupnost')
        .then(res => res.data)
        .then(data => {
            setLogDostupnost(data)
        })
    }

    useEffect(() => {
        getLogDostupnost();
        getLogKupnja();
    },[])

    return <div>
       <IntranetHeader/>
       <br/><br/><br/><br/><br/><br/><br/><br/>


       {
        logKupnja.map(lkup => <div key={lkup.rbr}>
            
            <div> <b>{lkup.datumPregleda}</b> {lkup.ime} {lkup.prezime} <b>{lkup.cijena}</b>€ <br/> {lkup.email} {lkup.drzava} {lkup.phone}  </div>
            
             </div>)
       }

       <br/>

       {
        logDostupnost.map(lkup => <div key={lkup.rbr}>
            
            <div> <b>{lkup.datumPregleda}</b> {lkup.dolazak} {lkup.odlazak}</div>
            
             </div>)
       }


    </div>
}

export default Logs
import React from "react";
import { useState } from 'react';
import axios from 'axios';
import './css/intranet.css'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import "./css/bootstrap.min.css"
import PregledMjeseca from "./PregledMjeseca";


function Mainpageintranet() {

    const [startdate, setStartDate] = useState(new Date());
    const [month,setMonth] = useState(new Date().getMonth() + 1);
    const [year,setYear] = useState(new Date().getFullYear());
    const [day,setDay] = useState(new Date().getDate());
    
    const [odlasci,setOdlasci] = useState([]);
    const [dolasci,setDolasci] = useState([]);
    const [status,setStatus] = useState([]);

    const[datumaktivnost,setdatumaktivnost] = useState(new Date());
    const[imeaktivnost,setimeaktivnost] = useState("Hot Tub");
    const[sobaaktivnost,setsobaaktivnost] = useState("Jela");
    const[komadiaktivnost,setkomadiaktivnost] = useState(0);
    const[cijenaaktivnost,setcijenaaktivnost] = useState(0);
    const[komentaraktivnost,setkomentaraktivnost] = useState("");
    const[placanje,setplacanje] = useState("kartica")

    const[otvorenUpis,setOtvorenUpis] = useState(false)
    const[poruka,setporuka] = useState("")

    const[aktivnosti,setAktivnosti] = useState([])

    function datumaktivnostChanged(event){
        const val = event.target.value;
        setdatumaktivnost(val)
    }

    function imeaktivnostChanged(event) {
        const val = event.target.value;
        setimeaktivnost(val)
    }

    function sobaaktivnostChanged(event) {
        const val = event.target.value;
        setsobaaktivnost(val)
    }

    function komadiaktivnostChanged(event) {
        const val = event.target.value;
        setkomadiaktivnost(val)
    }

    function cijenaaktivnostChanged(event) {
        const val = event.target.value;
        setcijenaaktivnost(val)
    }

    function komentaraktivnostChanged(event) {
        const val = event.target.value;
        setkomentaraktivnost(val)
    }

    function placanjeaktivnostChanged(event){
        const val = event.target.value;
        setplacanje(val)
    }

    function tooglupisaktivnost() {
        setOtvorenUpis(!otvorenUpis)
        if(otvorenUpis) {
            document.getElementById("upisaktiv").style.display = "block"
        } else {
            document.getElementById("upisaktiv").style.display = "none"
        }
    }

    function predajAktivnost() {
        axios.post("/novaaktivnost",{
            datum : datumaktivnost,
            ime : imeaktivnost,
            soba : sobaaktivnost,
            komadi : komadiaktivnost,
            cijena : cijenaaktivnost,
            komentar : komentaraktivnost,
            placanje : placanje,
            korisnik : sessionStorage.getItem("username")
        })
        .then(res => res.data)
        .then(data => setporuka(data))
    }

    React.useEffect(() => {
        ucitajzadatum();
        tooglupisaktivnost();
        getUpisaneAktivnosti();
    },[])


    


    function ucitajzadatum() {

        let mon = "" + month;
        if(mon.length == 1) {
            mon = "0" + mon
        }
        let dayy = "" + day;
        if(dayy.length == 1) {
            dayy = "0" + dayy
        }


        axios.get('/sobedata?date='+year + "-"+mon+"-"+dayy)
        .then((res) => res.data)
        .then(data => {

            console.log(data);

            setStatus(data);

        });

        ucitajOdlaske();
        ucitajDolaske();
    }

    function ucitajOdlaske() {

        let mon = "" + month;
        if(mon.length == 1) {
            mon = "0" + mon
        }
        let dayy = "" + day;
        if(dayy.length == 1) {
            dayy = "0" + dayy
        }

        axios.get('/sobeodlasci?date='+year + "-"+mon+"-"+dayy)
        .then((res) => res.data)
        .then(data => {

            console.log(data);

            for(let i = 0; i < data.length; i++) {
                if(data[i].ciscenje === false) {
                    data[i].ciscenje = "Ne"
                } else {
                    data[i].ciscenje = "Da"
                }
                if(data[i].sljedeciDolazak === null) {
                    data[i].sljedeciDolazak = "Nema ga još"
                } 

            }
            setOdlasci(data);
        });
    }

    function ucitajDolaske() {

        let mon = "" + month;
        if(mon.length == 1) {
            mon = "0" + mon
        }
        let dayy = "" + day;
        if(dayy.length == 1) {
            dayy = "0" + dayy
        }

        axios.get('/sobedolasci?date='+year + "-"+mon+"-"+dayy)
        .then((res) => res.data)
        .then(data => {

            console.log(data);

           
            setDolasci(data);
        });
    }


    function getUpisaneAktivnosti() {
        axios.get('getAktivnosti')
        .then(res => res.data)
        .then(data => {
            console.log(data)

            setAktivnosti(data)
        })
    }


    return (<div>

        <Button onClick={()=>{tooglupisaktivnost()}}>Otvori/Zatvori upis aktivnosti</Button><br/><br/><br/>
        <div id="upisaktiv">
            <label for={"Datum"}>Datum : </label><br/>
            <input value={datumaktivnost} onChange={datumaktivnostChanged} name="Datum" type={"date"}/><br/>
            <label for={"Aktivnost"}>Aktivnost : </label><br/>
            <select value={imeaktivnost} onChange={imeaktivnostChanged} name="Aktivnost">
                <option value="Hot Tub">
                    Hot Tub
                </option>
                <option value={"Sauna"}>
                    Sauna
                </option>
                <option value={"Vino"}>
                    Vino
                </option>
                <option value={"Med"}>
                    Med
                </option>
                <option value={"Mini bar"}>
                    Mini bar 
                </option>
                <option value={"Krevetic za dijete"}>
                    Krevetic za dijete
                </option>
            </select><br/>
            <label for={"Soba"}>Soba : </label><br/>
            <select value={sobaaktivnost} onChange={sobaaktivnostChanged} name="Soba">
                <option value="Jela">
                    Jela
                </option>
                <option value={"Lika"}>
                    Lika
                </option>
                <option value={"Zora"}>
                    Zora
                </option>
                <option value={"Lada"}>
                    Lada
                </option>
                <option value={"Korana"}>
                    Korana 
                </option>
                <option value={"Una"}>
                    Una
                </option>
            </select><br/>
            <label for={"Komadi"}>Broj komada proizvoda / broj ljudi hottub : </label><br/>
            <input value={komadiaktivnost} onChange={komadiaktivnostChanged} name="Komadi" type={"number"}/><br/>
            <label for={"Cijena"}>Naplačena cijena : </label><br/>
            <input value={cijenaaktivnost} onChange={cijenaaktivnostChanged} name="Cijena" type={"number"}/>€<br/>
            <label for={"Komentar"}>Artikl iz mini bara/Komentar : </label><br/>
            <input value={komentaraktivnost} onChange={komentaraktivnostChanged} name="Komentar" type={"text"}/><br/><br/>
            <label for={"Placanje"}>Vrsta Placanja</label><br/>
            <select value={placanje} onChange={placanjeaktivnostChanged} name="Placanje">
                <option value = "kartica">kartica</option>    
                <option value = "cash">cash</option>
            </select><br/>
            <div>{poruka}</div>
            <br/>
            <Button type={"reset"}>Reset</Button> &nbsp;
            <Button onClick={()=>{predajAktivnost()}}>Submit</Button><br/><br/><br/>

        </div>

        
        <DatePicker selected={startdate} onChange={(date) => {setStartDate(date); setDay(date.getDate()); setMonth(date.getMonth() + 1); setYear(date.getFullYear());}} inline/>
        <Button onClick={() => {ucitajzadatum()}}>Search for a date</Button><br/><br/>

        <br/><br/>

        <div className="row">
                <div className="col-md"><p class=" font-weight-bold text-xl">Selected date : {day}. {month}. {year}</p></div>
            </div>
        <br/> 

        <div className="container">
            
            <h1>Room Status</h1>
            <div className="row border-bottom">
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Room</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Arrival</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Departure</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Next arrival</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Occupied over the day</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Full clean</p>
                </div>
            </div>
                {
                    status.map(soba => <div key={soba.simbolicName} className="row justify-content-center">

                            <div class="col">
                                <p className="text-lg">{soba.simbolicName}</p>
                            </div>
                            {
                                soba.odlazak_gosta == true &&
                                <div class="col">
                                    <i class="bi bi-check-lg"></i>
                                </div>
                            }
                            {
                                soba.odlazak_gosta != true &&
                                <div class="col">
                                    <i class="bi bi-x-circle-fill"></i>
                                </div>
                            }
                            {
                                soba.dolazak_gosta == true &&
                                <div class="col">
                                    <i class="bi bi-check-lg"></i>
                                </div>
                            }
                            {
                                soba.dolazak_gosta != true &&
                                <div class="col">
                                    <i class="bi bi-x-circle-fill"></i>
                                </div>
                            }
                                                        {
                                soba.sljedeciDolazak != null &&
                                <div class="col">
                                    <p className="text-lg">{soba.sljedeciDolazak}</p>
                                </div>
                            }
                            {
                                soba.sljedeciDolazak == null &&
                                <div class="col">
                                    <i class="bi bi-x-circle-fill"></i>
                                </div>
                            }
                            

                            {
                                soba.zauzeta == true &&
                                <div class="col">
                                    <i class="bi bi-check-lg"></i>
                                </div>
                            }
                            {
                                soba.zauzeta != true &&
                                <div class="col">
                                    <i class="bi bi-x-circle-fill"></i>
                                </div>
                            }
                            {
                                soba.ciscenje == true &&
                                <div class="col">
                                    <i class="bi bi-check-lg"></i>
                                </div>
                            }
                            {
                                soba.ciscenje != true &&
                                <div class="col">
                                    <i class="bi bi-x-circle-fill"></i>
                                </div>
                            }

                        </div>
                    )
                }

        </div>
        <br/><br/>

        <div className="container">
            
            <h1>Departures</h1>
            <div className="row border-bottom">
                <div class="col-sm">
                    <p className="font-weight-bold text-lg">Room</p>
                </div>
                <div class="col-sm">
                    <p className="font-weight-bold text-lg">Reservation</p>
                </div>
                <div class="col-sm">
                    <p className="font-weight-bold text-lg">Name</p>
                </div>
                <div class="col-sm">
                    <p className="font-weight-bold text-lg">Country</p>
                </div>
                <div class="col-sm">
                    <p className="font-weight-bold text-lg">Email</p>
                </div>
                <div class="col-sm">
                    <p className="font-weight-bold text-lg">Adults</p>
                </div>
                <div class="col-sm">
                    <p className="font-weight-bold text-lg">Children</p>
                </div>
                <div class="col-sm">
                    <p className="font-weight-bold text-lg">Requests</p>
                </div>
            </div>
                {
                    odlasci.map(odlazak => <div key={odlazak.simbolicName} className="row justify-content-center">

                            <div class="col">
                                <p className="text-lg">{odlazak.simbolicName}</p>
                            </div>
                            <div class="col">
                                <i class="bi bi-check-lg"></i>
                            </div>
                            <div class="col">
                                <p className=" text-lg">{odlazak.gost.ime} {odlazak.gost.prezime}</p>
                            </div>
                            <div class="col">
                                <p className=" text-lg">{odlazak.gost.drzava}</p>
                            </div>
                            <div class="col">
                                <p className=" text-lg">{odlazak.gost.email}</p>
                            </div>
                            <div class="col">
                                <p className="text-lg">{odlazak.brojOdraslih}</p>
                            </div>
                            <div class="col">
                                <p className="text-lg">{odlazak.brojDjece}</p>
                            </div>
                            <div class="col">
                                <p className=" text-lg">{odlazak.napomena}</p>
                            </div>
                            

                        </div>
                    )
                }

        </div>

        <br/><br/>

        <div className="container">
            
            <h1>Arrivals</h1>
            <div className="row border-bottom">
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Room</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Check-in</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Early check in</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">guest</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">country</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Email</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Mob</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">adults</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Children</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Requests</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">PIN</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Sauna</p>
                </div>
                <div class="col-lg">
                    <p className="font-weight-bold text-lg">Hottub</p>
                </div>
            </div>
            {
                    dolasci.map(dolazak => <div key={dolazak.simbolicName} className="row justify-content-center">

                            <div class="col">
                                <p className="text-lg">{dolazak.simbolicName}</p>
                            </div>

                            {
                                dolazak.checkedin == true &&
                                <div class="col">
                                    <i class="bi bi-check-lg"></i>
                                
                                </div>
                            }
                            {
                                dolazak.checkedin != true &&
                                <div class="col">
                                    <a href={"/rezervacija/checkin/" + dolazak.id_rez}>Check in</a>
                                </div>
                            }

                            <div class="col">
                                <p className=" text-lg">{dolazak.earlycheckin}</p>
                            </div>
                            <div class="col">
                                <p className=" text-lg">{dolazak.gost.ime} {dolazak.gost.prezime}</p>
                            </div>
                            <div class="col">
                                <p className=" text-lg">{dolazak.gost.drzava}</p>
                            </div>
                            <div class="col">
                                <p className=" text-lg">{dolazak.gost.email}</p>
                            </div>
                            <div class="col">
                                <p className=" text-lg">{dolazak.gost.brojMobitela}</p>
                            </div>
                            <div class="col">
                                <p className="text-lg">{dolazak.brojOdraslih}</p>
                            </div>
                            <div class="col">
                                <p className="text-lg">{dolazak.brojDjece}</p>
                            </div>
                            <div class="col">
                                <p className=" text-lg">{dolazak.napomena}</p>
                            </div>
                            <div class="col">
                                <p className=" text-lg">{dolazak.pin}</p>
                            </div>
                            <div class="col">
                                <p className=" text-lg">{dolazak.sauna}</p>
                            </div>
                            <div class="col">
                                <p className=" text-lg">{dolazak.hottub}</p>
                            </div>
                        </div>
                    )
                }

        </div>

        <br/><br/><br/>
        <PregledMjeseca/>
        <h1>Aktivnosti</h1>
        {
            aktivnosti.map(aktivnost => <div key={aktivnost.rbr}>

                    <div><b>{aktivnost.datum}</b> {aktivnost.ime}, {aktivnost.soba}<br/>{aktivnost.komadi}kom {aktivnost.cijena}€ \{aktivnost.komentar}\ {aktivnost.placanje}</div>
                    <br/>

            </div>)
        }
    

    </div>
    )
}

export default Mainpageintranet
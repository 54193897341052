import './css/App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Mainpage from './mainpage';
import LoginForm from './LoginForm'
import AuthHandler from "./AuthHandler";
import React, { useState } from "react";
import Admin from './admin';
import Manager from './manager';
import Promokod from './promokod';
import Maillista from './maillista';
import Rezerviraj from './rezerviraj';
import PregledRezervacija from './pregledRezervacija';
import Checkin from './Checkin';
import Uredi from './uredi';
import Izvjestaji from './izvjestaji';
import Logs from './logs';
import Terms from './terms';
import Radnici from './radnici';
import Place from './place';

function App() {


  if(AuthHandler.isUserLoggedIn()) {
    AuthHandler.setupAxiosInterceptors(AuthHandler.createBasicAuthToken(AuthHandler.getLoggedInUserName(), AuthHandler.getLoggedInPassword()));
  }

  let languageStoredInLocalStorage = localStorage.getItem("language");

  let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "English"); 

  

  function storeLanguageInLocalStorage(language) {
    localStorage.setItem("language", language);
  }
  


  if (AuthHandler.isUserLoggedIn()) {


    if(AuthHandler.isUserAdminLogedIn()) {
      return (
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Mainpage language={language}
                                                          handleSetLanguage={language => {
                                                            setLanguage(language);
                                                            storeLanguageInLocalStorage(language);
                                                          }} />}/>
              <Route path='/terms' element={<Terms language={language}
                                                          handleSetLanguage={language => {
                                                            setLanguage(language);
                                                            storeLanguageInLocalStorage(language);
                                                          }} />}/>
              <Route path='/admin' element={<Admin/>}/>
              <Route path='/promokoduredi' element={<Promokod/>}/>
              <Route path='/maillista' element={<Maillista/>}/>
              <Route path='/uredi' element={<Uredi/>}/>
              <Route path='/izvjesca' element={<Izvjestaji/>}/>
              <Route path='/logs' element={<Logs/>}/>
              <Route path='/radnici' element={<Radnici/>}/>
              <Route path='/rezervacija/:id' element={<PregledRezervacija language={language}
                                                          handleSetLanguage={language => {
                                                            setLanguage(language);
                                                            storeLanguageInLocalStorage(language);
                                                          }}
                                                          />} />
          <Route path='/rezervacija' element={<Rezerviraj language={language}
                                                          handleSetLanguage={language => {
                                                            setLanguage(language);
                                                            storeLanguageInLocalStorage(language);
                                                          }}
                                              />} />
          <Route path='/rezervacija/checkin/:id' element={<Checkin language={language}
                      handleSetLanguage={language => {
                        setLanguage(language);
                        storeLanguageInLocalStorage(language);
                      }}
          />} />
            </Routes>    
          </BrowserRouter>     
        </div>
      )
    } else if(AuthHandler.isUserManagerLogedIn()) {
      return (
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Mainpage language={language}
                                                          handleSetLanguage={language => {
                                                            setLanguage(language);
                                                            storeLanguageInLocalStorage(language);
                                                          }} />}/>
             <Route path='/terms' element={<Terms language={language}
                                                          handleSetLanguage={language => {
                                                            setLanguage(language);
                                                            storeLanguageInLocalStorage(language);
                                                          }} />}/>
               <Route path='/admin' element={<Manager/>}/>
              <Route path='/promokoduredi' element={<Manager/>}/>
              <Route path='/maillista' element={<Manager/>}/>
              <Route path='/uredi' element={<Manager/>}/>
              <Route path='/izvjesca' element={<Manager/>}/>
              <Route path='/logs' element={<Manager/>}/>
              <Route path='/place' element={<Place/>}/>

              <Route path='/rezervacija/:id' element={<PregledRezervacija language={language}
                                                          handleSetLanguage={language => {
                                                            setLanguage(language);
                                                            storeLanguageInLocalStorage(language);
                                                          }}
                                                          />} />
          <Route path='/rezervacija' element={<Rezerviraj language={language}
                                                          handleSetLanguage={language => {
                                                            setLanguage(language);
                                                            storeLanguageInLocalStorage(language);
                                                          }}
                                              />} />
          <Route path='/rezervacija/checkin/:id' element={<Checkin language={language}
                      handleSetLanguage={language => {
                        setLanguage(language);
                        storeLanguageInLocalStorage(language);
                      }}
          />} />
            </Routes>    
          </BrowserRouter>     
        </div>
      )
    }
  }


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Mainpage language={language}
                                                          handleSetLanguage={language => {
                                                            setLanguage(language);
                                                            storeLanguageInLocalStorage(language);
                                                          }} />}/>
          <Route path='/terms' element={<Terms language={language}
                                                          handleSetLanguage={language => {
                                                            setLanguage(language);
                                                            storeLanguageInLocalStorage(language);
                                                          }} />}/>
          <Route path='/admin' element={<LoginForm/>} />
          <Route path='/place' element={<LoginForm/>}/>
          <Route path='/radnici' element={<LoginForm/>}/>

          <Route path='/rezervacija/:id' element={<PregledRezervacija language={language}
                                                          handleSetLanguage={language => {
                                                            setLanguage(language);
                                                            storeLanguageInLocalStorage(language);
                                                          }}
                                                          />} />
          <Route path='/rezervacija' element={<Rezerviraj language={language}
                                                          handleSetLanguage={language => {
                                                            setLanguage(language);
                                                            storeLanguageInLocalStorage(language);
                                                          }}
                                              />} />
          <Route path='/rezervacija/checkin/:id' element={<Checkin language={language}
                      handleSetLanguage={language => {
                        setLanguage(language);
                        storeLanguageInLocalStorage(language);
                      }}
          />} />
                                  
        </Routes>    
      </BrowserRouter>
    </div>
  );
}

export default App;

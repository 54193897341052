import React, { useEffect, useState } from "react";
import ManagerHeader from "./managerheader";
import { Button } from "react-bootstrap";
import axios from "axios";



function Place() {

    const [tab,setTab] = useState("RadniSati")
    const [radnik,setRadnik] = useState("")


    const [stand_from1,setstandfrom1] = useState("")
    const [stand_to1,setstandto1] = useState("")
    const [active1,setactive1] = useState(true)
    const [stand_from2,setstandfrom2] = useState("")
    const [stand_to2,setstandto2] = useState("")
    const [active2,setactive2] = useState(true)

    const [stand_from3,setstandfrom3] = useState("")
    const [stand_to3,setstandto3] = useState("")
    const [razlog3,setrazlog3] = useState("")
    const [active3,setactive3] = useState(false)

    const [stand_from4,setstandfrom4] = useState("")
    const [stand_to4,setstandto4] = useState("")
    const [razlog4,setrazlog4] = useState("")
    const [active4,setactive4] = useState(false)

    const [currentDate,setCurrentDate] = useState(new Date().toISOString().slice(0, 10))
    const [currentTime,setCurrentTime] = useState(new Date().getHours())

    const [selectedDate,setSelectedDate] = useState(new Date().toISOString().slice(0, 10))

    const [rad,setrad] = useState([])

    useEffect(() => {
        getRadnik()
        getRad()
    },[])

    useEffect(() => {
        getRad()
    },[selectedDate])

    function getRadnik() {
        axios.get("/radnik")
        .then(res => res.data)
        .then(data => {
            console.log(data)
            setRadnik(data)
            setstandfrom1(data.standard_from)
            setstandfrom2(data.standard_from2)
            setstandto1(data.standard_to)
            setstandto2(data.standard_to2)

        })
    }

    function getRad() {
        axios.get("/rad?datum="+selectedDate)
        .then(res => res.data)
        .then(data => {
            for(let r of data) {
                console.log(r)
            }
            setrad(data)
        })
    }

    function changefrom1(e) {
        let time = e.target.value
        if(time < radnik.standard_from || time > radnik.standard_to || time >= stand_to1) {
            console.log("Ne može se dogoditi ta promjena")
        } else {
            setstandfrom1(time)
        }
    }
    function changeto1(e) {
        let time = e.target.value
        if(time < radnik.standard_from || time > radnik.standard_to || time <= stand_from1) {
            console.log("Ne može se dogoditi ta promjena")
        } else {
            setstandto1(time)
        }
    }
    function changefrom2(e) {
        let time = e.target.value
        if(time < radnik.standard_from2 || time > radnik.standard_to2 || time >= stand_to2) {
            console.log("Ne može se dogoditi ta promjena")
        } else {
            setstandfrom2(time)
        }
    }
    function changeto2(e) {
        let time = e.target.value
        if(time < radnik.standard_from2 || time > radnik.standard_to2 || time <= stand_from2) {
            console.log("Ne može se dogoditi ta promjena")
        } else {
            setstandto2(time)
        }
    }
    function changefrom3(e) {
        let time = e.target.value
        if((time >= radnik.standard_from && time <= radnik.standard_to) || (time >= radnik.standard_from2 && time <= radnik.standard_to2)) {
            console.log("Ne može se dogoditi ta promjena")
        } else {
                setstandfrom3(time)
        }
    }
    function changeto3(e) {
        let time = e.target.value
        if( (time >= radnik.standard_from && time <= radnik.standard_to) || (time >= radnik.standard_from2 && time <= radnik.standard_to2) ) {
            console.log("Ne može se dogoditi ta promjena")
        } else {
                setstandto3(time)
        }
    }
    function changefrom4(e) {
        let time = e.target.value
        if((time >= radnik.standard_from && time <= radnik.standard_to) || (time >= radnik.standard_from2 && time <= radnik.standard_to2) ) {
            console.log("Ne može se dogoditi ta promjena")
        } else {
                setstandfrom4(time)
        }
    }
    function changeto4(e) {
        let time = e.target.value
        if( (time >= radnik.standard_from && time <= radnik.standard_to) || (time >= radnik.standard_from2 && time <= radnik.standard_to2) ) {
            console.log("Ne može se dogoditi ta promjena")
        } else {
                setstandto4(time)
        }
    }

    
    function posalji() {
        if(currentDate !== selectedDate) {
            return
        }
        let arr = []
        if(active1) {
            arr.push(
                {
                    od : stand_from1,
                    to : stand_to1,
                    razlog : "Standard",
                    preko : false,
                    datum : selectedDate,
                    km : 0
                }
            )
        }
        if(active2) {
            arr.push(
                {
                    od : stand_from2,
                    to : stand_to2,
                    razlog : "Standard",
                    preko : false,
                    datum : selectedDate,
                    km : 0
                }
            )
        }
        if(active3 && stand_from3 !== "" && stand_to3 !== "") {
            arr.push(
                {
                    od : stand_from3,
                    to : stand_to3,
                    razlog : razlog3,
                    preko : true,
                    datum : selectedDate,
                    km : 0
                }
            )
        }
        if(active4 && stand_from4 !== "" && stand_to4 !== "") {
            arr.push(
                {
                    od : stand_from4,
                    to : stand_to4,
                    razlog : razlog4,
                    preko : true,
                    datum : selectedDate,
                    km : 0
                }
            )
        }
        axios.post("/rad",
            arr
        )
        .then((res) => {
            window.location.reload()
        })

    }

    return (        
        <div>
        <ManagerHeader/>
        <br/>        
        <br/>
        <br/>
        <br/>
        <br/>
        <div>
            <Button className="btn-primary m-2" onClick={() => setTab("RadniSati")}>Radni sati</Button>
        </div>
       
        {
            tab === "RadniSati" &&
            <div>
                <div className="naslov"> Radni Sati </div>
                <div> 
                    Radnik : {radnik.ime} {radnik.prezime} <br/>
                    Dogovoreno standardno radno vrijeme : <br/> {radnik.standardnoRadnoVrijeme}
                </div>
                <br/>
                <input value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} type="date"></input>

                <br/><br/>
                {
                    currentDate === selectedDate && rad.length === 0 &&
                    <h3 className="text-primary">
                        Imate još cca. {24 -currentTime} sati za popuniti izvještaj
                    </h3>
                }
                {
                    currentDate < selectedDate &&
                    <h3 className="text-warning">
                        Ovaj datum je u budućnosti.
                    </h3>
                }
                {
                    currentDate >= selectedDate && rad.length !== 0 &&
                    <h5 className="text-success">
                        Ovaj izvještaj je zatvoren. Hvala Vam na popunjavanju
                    </h5>
                }
                {
                    currentDate > selectedDate && rad.length === 0 &&
                    <h5 className="text-danger">
                        Propustili ste popuniti ovaj izvještaj. Ako se ne javite Adminu za naknadni unos podataka, ovaj radni dan Vam će se gledati kao da niste radili.
                    </h5>
                }

                {
                    currentDate === selectedDate && rad.length === 0 && 
                        <div> 
                            <h5>Standardno vremena možete samo smanjiti, ako Vam je potreban upis prekovremenog, to odabite klikom na crveni gumb </h5>
                            <h5>Jednom poslano vrijeme nije moguće izmjeniti </h5>
                            <h5>Svaki prekovremeni zahtjeva potvrdu admina </h5>
                            <h5>Platna lista se generira pomoću unesenih i potvrđenih podataka tako da unosite samo istinitne i točne podatke </h5>

                            {
                                active1 && <div>
                                    <input type="time" value={stand_from1} step="1" onChange={(e) => changefrom1(e)} /> -
                                    <input type="time" value={stand_to1} step="1" onChange={(e) => changeto1(e)}/>
                                    <input className="mx-2" type="text" value={"Standardni poslovi"}/>
                                    <Button onClick={() => setactive1(false)} className="btn btn-sm btn-warning">X</Button>
                                </div>
                            }
                            {
                                !active1 && 
                                <Button onClick={() => setactive1(true)} className="btn btn-info m-1">Vrati 1.radno vrijeme</Button>
                            }
                            <br/>
                            {
                                active2 && <div>
                                    <input type="time" value={stand_from2} step="1" onChange={(e) => changefrom2(e)}/> -
                                    <input type="time" value={stand_to2} step="1" onChange={(e) => changeto2(e)}/>
                                    <input className="mx-2" type="text" value={"Standardni poslovi"}/>
                                    <Button onClick={() => setactive2(false)} className="btn btn-sm btn-warning">X</Button>
                                </div>
                            }
                            {
                                !active2 && 
                                <Button onClick={() => setactive2(true)} className="btn btn-info m-1">Vrati 2.radno vrijeme</Button>
                            }
                            
                            <div>
                                <Button onClick={() => posalji()} className="btn btn-success m-2">Potvrdi radno vrijeme</Button> <br/>
                                <h3>Prekovremene</h3>
                                {
                                active3 && <div>
                                    <input type="time" value={stand_from3} step="1" onChange={(e) => changefrom3(e)}/> -
                                    <input type="time" value={stand_to3} step="1" onChange={(e) => changeto3(e)}/>
                                    <input className="mx-2" type="text" placeholder="Razlog prekovremenog" value={razlog3} onChange={(e) => setrazlog3(e.target.value) }/>
                                    <Button onClick={() => setactive3(false)} className="btn btn-sm btn-warning">X</Button>
                                </div>
                                }
                                {
                                    !active3 && 
                                    <Button onClick={() => setactive3(true)} className="btn btn-danger m-1">Dodaj prekovremeno 1</Button>
                                }
                                <br/>
                                {
                                active4 && <div>
                                    <input type="time" value={stand_from4} step="1" onChange={(e) => changefrom4(e)}/> -
                                    <input type="time" value={stand_to4} step="1" onChange={(e) => changeto4(e)}/>
                                    <input className="mx-2" type="text" placeholder="Razlog prekovremenog" value={razlog4} onChange={(e) => setrazlog4(e.target.value) }/>
                                    <Button onClick={() => setactive4(false)} className="btn btn-sm btn-warning">X</Button>
                                </div>
                                }
                                {
                                    !active4 && 
                                    <Button onClick={() => setactive4(true)} className="btn btn-danger m-1">Dodaj prekovremeno 2</Button>
                                }

                            </div>
                        </div>
                    }
                    {
                        rad.length !== 0 &&
                        <div>
                            {
                                rad.map(r => <div key={r.idRad}>{r.radfrom}-{r.rad_to} <br/> {r.razlog} <br/> {r.prekovremeno ? <div className="text-warning">Prekovremeno</div> : ""} <br/>  {r.odobreno ? <div className="text-success">Odobreno</div> : <div className="text-danger">Nije još odobreno</div>} <hr/> </div>)
                            }    
                        </div>

                    }
            </div>
        }        
        {
            tab === "Godisnji" &&
            <div>
                <div className="naslov"> Godisnji </div>

                
            </div>
        }
        {
            tab === "Izvjestaj" &&
            <div>
                <div className="naslov"> Izvjestaj </div>

                
            </div>
        }    

        

        </div>
    )
}

export default Place
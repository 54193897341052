import React from 'react';
import Header from './header';
import Footer from "./footer";
import { useState } from 'react';
import Rooms from './Rooms';
import axios from 'axios';
import  './css/rezervacija.css'
import { Button } from 'react-bootstrap';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import "./css/bootstrap.min.css"
import "./css/style.css"
import "./css/responsive.css"
import "./css/custom.css"
import CheckoutForm from './CheckoutForm';
import sivasoba from "./static/una2.webp"
import okerkada from "./static/zora4.jpg"
import pinksoba from "./static/korana1.jpg"
import zelenasup from "./static/lada1.jpg"
import supbath from "./static/jela1.jpg"
import loading from "./images/loading.png"
import lika5 from "./static/lika5.jpeg"


const stripePromise = loadStripe('pk_live_51LRaprDms8bv2bTJr2reZQiymHPQsHuuBaw1Gvpbe9dfsRaFzK6qCFof1aaUspKiE4GdYsA60QAvXzjTL2VZP6y600KHhfSXHz');



function Rezerviraj(props) {

    const [clientSecret, setClientSecret] = useState("");

    function novoplacanje(){
        // Create PaymentIntent as soon as the page loads
        fetch("/create-payment-intent", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ price: finalCijena }),
        })
          .then((res) => res.json())
          .then((data) => setClientSecret(data.clientSecret));
      }
    
      const appearance = {
        theme: 'stripe',

      };
      const options = {
        clientSecret,
        appearance,
      };


    const [dolazakDatum,setDolazakDatum] = useState("");
    const [odlazakDatum,setOdlazakDatum] = useState("");
    const [djecanumber,setDjecanumber] = useState(0);
    const [brojsoba,setBrojsoba] = useState(0);
    const [error,setError] = useState("");
    const [errordodatnipodaci,seterrordodatnipodaci] = useState("")

    const [finalRooms,setFinalRooms] = useState([]);
    const [finalCijena,setFinalCijena] = useState(0);
    const [odraslinumber,setOdraslinumber] = useState(0);
    
    const [sobe,setSobe] = useState([]);
    const [imekupca,setImekupca] = useState("");
    const [prezimekupca,setPrezimekupca] = useState("");
    const [emailkupca,setemailkupca] = useState("");
    const [phonekupac,setPhonekupac] = useState("");
    const [drzavakupca,setDrzavakupca] = useState("");
    const [napomenakupca,setnapomenakupca] = useState("");

    const [promocode,setPromocode] = useState("")
    const [promocodeporuka,setPromocodeporuka] = useState("")
    const [promocodeapplied,setPromocodeApplied] = useState(false)

    const [sobeCijene,setSobeCijene] = useState(new Map())    
    const [sobeBrojOsoba,setSobeBrojOsoba] = useState(new Map())

    const [soldout,setsoldout] = useState("")



    let content = {
        English: {
            labelDatum : "Select desired dates",
            labelDolazak : "Date of arrival",
            labelOdlazak : "Date of departure",
            buttonDostupnost : "Check availability",
            labelBrojOsoba : "Select number of guests",
            brojOdraslih: "Number of people (3+ years)",
            brojDjece: "Number of children (0-14 years)",
            labelbrojsoba:"Number of rooms ",
            errorDatum: "DATE ERROR : Date must be set and departure must be after arrival",
            errorBrojOdrasli: "ERROR : Number of adults can't be 0",
            paketSoba: "Included rooms",
            singlebed:"Single bed",
            bracniKrevet:"King size bed",
            odaberi:"Confirm option",
            rasprodano:"Sold out",
            ime:"Name:",
            prezime:"Surname:",
            država:"Country of residence:",
            napomena:"Message to host:",
            labelUnosDodatni:"Fill the required data to complete the reservation",
            sažetakRezervacije:"Reservation summary",
            buttonplacanje:"Continue to payment",
            errorsvapolja:"All fields with * must be filled",
            errormail:"Email has a wrong format.",
            Promocode:"Promocode",
            primjeni:"Apply",
            cijena:"Price",
            promocodeKrivi:"Applied promocode doesn't exist",
            promocodeapplied:"Applied promocode : ",
            phoneerror : "Phone number is not in the correct form. Please use the +(country code)(phonenumber) template",
            labelpay:"Please enter the data and pay :",
            simbolicname : "Room name : ",
            ukljucendorucak : "Breakfast included in price",
            bathtubincluded:"*All Luxury rooms are equiped with a bathtub*",
            odaberitebrojljudi:"Select number of people staying in this room(3+ years old)",
            kreveticnaplata:"Crib costs extra (pay at the property)",
            finishorder : "Finish the order for all selected rooms",
            soldout : "Sold out for your dates. Please select different dates.",
            popust:"10% discount for all 2024. reservations, use code : summer2024"


        },
        
        German: {
            labelDatum : "Wählen Sie die gewünschten Daten aus",
            labelDolazak : "Datum der Ankunft",
            labelOdlazak : "Abreisedatum",
            buttonDostupnost : "Verfügbarkeit prüfen",
            labelBrojOsoba : "Wählen Sie die Anzahl der Personen",
            brojOdraslih: "Anzahl Erwachsene (3+ Jahre)",
            labelbrojsoba:"Anzahl der Räume ",
            errorDatum: "FEHLER: Das Datum muss eingestellt sein und die Abreise muss nach der Ankunft erfolgen",
            errorBrojOdrasli: "FEHLER: Die Anzahl der Erwachsenen darf nicht 0 sein",
            paketSoba: "Zimmer enthalten",
            singlebed:"Bett für 1 Person",
            bracniKrevet:"Kingsize-Bett",
            odaberi:"Wählen",
            rasprodano:"Ausverkauft",
            ime:"Nmae:",
            prezime:"Nachname:",
            država:"Land des Wohnsitzes:",
            napomena:"Anmerkung:",
            labelUnosDodatni:"Geben Sie die erforderlichen Informationen ein, um die Reservierung abzuschließen",
            sažetakRezervacije:"Buchungsübersicht",
            buttonplacanje:"Gehen Sie zur Zahlung",
            errorsvapolja:"Alle Felder mit * müssen ausgefüllt werden",
            errormail:"Falsches E-Mail-Format",
            Promocode:"Werbe-Code",
            primjeni:"Sich bewerben",
            cijena:"Preis",
            promocodeKrivi:"Der eingegebene Aktionscode existiert nicht",
            promocodeapplied:"Angewendeter Aktionscode : ",
            phoneerror:"Die Telefonnummer hat nicht das richtige Format. Bitte verwenden Sie die Vorlage +(Ländercode)(Telefonnummer).",
            labelpay:"Bitte Daten eingeben und bezahlen:",
            simbolicname : "Room name : ",
            ukljucendorucak : "Frühstück im Preis inbegriffen",
            bathtubincluded:"*Alle Luxuszimmer sind mit einer Badewanne ausgestattet*",
            odaberitebrojljudi:"Wählen Sie die Anzahl der Personen aus, die in diesem Zimmer übernachten",
            kreveticnaplata:"Kinderbett kostet extra (in der Unterkunft zu bezahlen)",
            finishorder : "Beenden Sie die Bestellung",
            soldout : "Für Ihre Daten ausverkauft. Bitte wählen Sie andere Daten aus.",
            popust:"10% Rabatt für alle 2024. Reservierungen, verwenden Sie den Code: summer2024"


        },
        Croatian: {
            labelDatum : "Odaberite željene datume",
            labelDolazak : "Datum dolaska",
            labelOdlazak : "Datum odlaska",
            buttonDostupnost : "Provjeri dostupnost",
            labelBrojOsoba : "Odaberite broj osoba",
            brojOdraslih: "Broj odraslih (14+ godina)",
            brojDjece: "Broj djece (0-14 godina)",
            labelbrojsoba: "Broj soba ",
            errorDatum: "GREŠKA : Datum mora biti postavljen i odlazak mora biti poslije dolaska",
            errorBrojOdrasli: "GREŠKA : Broj odraslih ne može biti 0",
            paketSoba: "Paket soba",
            singlebed:"Krevet za 1 osobu",
            bracniKrevet:"King size krevet",
            odaberi:"Odaberi",
            rasprodano:"Rasprodano",
            ime:"Ime:",
            prezime:"Prezime:",
            država:"Država prebivališta:",
            napomena:"Napomena:",
            labelUnosDodatni:"Unesite potrebne podatke za dovršenje rezervacije",
            sažetakRezervacije:"Sažetak rezervacije",
            buttonplacanje:"Kreni na plaćanje",
            errorsvapolja:"Sva polja s * moraju biti popunjena",
            errormail:"Nepravilni format emaila",
            Promocode:"Promokod",
            primjeni:"Primjeni",
            cijena:"Cijena",
            promocodeKrivi:"Uneseni promokod ne postoji",
            promocodeapplied:"Primjenjeni promokod : ",
            phoneerror:"Broj telefona nije u ispravnom obliku. Koristite predložak +(pozivni broj države)(telefonski broj).",
            labelpay:"Unesite podatke i platite : ",
            simbolicname : "Ime sobe : ",
            ukljucendorucak : "Dorućak uključen u sobu ",
            bathtubincluded:"*Sve Luxury sobe dolaze opremljene s kadom za kupanje*",
            odaberitebrojljudi:"Odaberite broj ljudi koji odsjeda u ovoj sobi(3+ godina)",
            kreveticnaplata:"Dječji krevetić uz nadoplatu (plaćanje u objektu)",
            finishorder : "Završite narudžbu za sve odabrane sobe",
            soldout : "Rasprodano za Vaše datume. Molimo odaberite druge datume.",
            popust:"10% popusta za sve rezervacije za 2024., koristite kod : summer2024"



        }
    }

   if(props.language === "English") {
      content = content.English
   } else if(props.language === "Croatian") {
      content = content.Croatian
   } else {
       content = content.German
   }
        
   function dolazakChanged(event) {
        const val = event.target.value;
        setDolazakDatum(val);
   }
   function imeChanged(event) {
    const val = event.target.value;
    setImekupca(val);
   }

   function prezimeChanged(event) {
    const val = event.target.value;
    setPrezimekupca(val);
   }
   function emailChanged(event) {
    const val = event.target.value;
    setemailkupca(val);
   }
   function phoneChanged(event) {
    const val = event.target.value;
    setPhonekupac(val);
   }
   function drzavaChanged(event) {
    const val = event.target.value;
    setDrzavakupca(val);
   }
   function napomenaChanged(event) {
    const val = event.target.value;
    setnapomenakupca(val);
   }

   function promocodeChanged(event) {
    const val = event.target.value;
    setPromocode(val);
   }

   function odlazakChanged(event) {
        const val = event.target.value;
        setOdlazakDatum(val);
   }
   function brojSobaChanged(event) {
    const val = event.target.value;
    setBrojsoba(val);
   }


   function provjeriDostupnost() {


        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let separator='-';

        let datefull = `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;
        

        if(dolazakDatum =="" || odlazakDatum == "" || odlazakDatum <= dolazakDatum || datefull > dolazakDatum || datefull >= odlazakDatum) {
            setError(content.errorDatum)
            return
        } else {
            setError("")
        }
        document.getElementById("load").style.display = "block"
        document.getElementById("dostupnostbtn").disabled = true

        axios.get('/dostupnesobe', {
            params : {
                dolazak: dolazakDatum,
                odlazak: odlazakDatum,
                brojOdraslih: odraslinumber,
                brojDjece: djecanumber,
                brojsoba: brojsoba
            }
        })
        .then(res => res.data)
        .then(data => {
            if(data.length === 0) {
                setsoldout(content.soldout)
            } else {
                setsoldout("")
            }
            setSobe(data)           
            setSobeCijene(new Map())
            setSobeBrojOsoba(new Map())
            for(let i = 0; i < sobe.length;i++) {
                document.getElementById("form"+sobe[i].sobaId).value = 0
            }
            document.getElementById("potvrdeno").style.display = "none";
            document.getElementById("load").style.display = "none"
            document.getElementById("dostupnostbtn").disabled = false

            document.getElementById("sobezaodabir").scrollIntoView();

        })


   }

   function potvrdi() {
       
    setPromocodeApplied(false)
    setPromocode("")
    setPromocodeporuka("")

    let sumacijeni = 0
    let odabraneSobe = []
    let brojLjudi = 0


    for(let i = 0; i < sobe.length;i++) {
        if(!sobeBrojOsoba.has((sobe[i].sobaId.toString()))) {
            continue
        }
        let osobe = sobeBrojOsoba.get((sobe[i].sobaId.toString()))
        if(osobe == 0) continue
        let price = sobeCijene.get((sobe[i].sobaId.toString()))
        brojLjudi += parseInt(osobe)
        sumacijeni += price
        sobe[i]['odrasli'] = osobe
        odabraneSobe.push(sobe[i])
    }

    setOdraslinumber(brojLjudi)
    setFinalCijena(sumacijeni)
    setFinalRooms(odabraneSobe)

    document.getElementById("potvrdeno").style.display = "block";
    document.getElementById("potvrdeno").scrollIntoView();

   }

   function primjeniPromocode() {
       setPromocodeApplied(true)


       axios.get("/promokod?id="+promocode)
       .then(res => res.data)
       .then(data => {
        
        if(data == 0) {
            setPromocodeApplied(false)
            setPromocode("")
            setPromocodeporuka(content.promocodeKrivi)
            return;
        }

        setPromocodeporuka(content.promocodeapplied + promocode)

        

        let newPrice = finalCijena * ((100 - data) / 100);
        setFinalCijena(Math.round(100*newPrice) / 100)        

        })

   }

   function nastaviPlacanje() {
       seterrordodatnipodaci("")

       if(imekupca == "" || prezimekupca =="" || emailkupca =="" || drzavakupca == "" || phonekupac == "") {
           seterrordodatnipodaci("Error : " + content.errorsvapolja)
           return;
       }
        let mailsplit = emailkupca.split("@")
        if(mailsplit.length != 2) {
           seterrordodatnipodaci("Error : " + content.errormail)
           return;
       }
       if(!phonekupac.startsWith("+")) {
            seterrordodatnipodaci("Error : " + content.phoneerror)
            return;
       }

       axios.post('/LogPlacanje', {
        imekupca: imekupca,
        prezimekupca: prezimekupca,
        emailkupca: emailkupca,
        drzavakupca : drzavakupca,
        emailkupca : emailkupca,
        phonekupac : phonekupac,
        finalCijena : finalCijena
      })

       document.getElementById("labelpay").style.display = "block";
       document.getElementById("labelpay").scrollIntoView();

       novoplacanje();
    // TU SE DESI PLACANJE I DOBI POTVRDA OD VANJSKE USLUGE DA JE PLACANJE IZVRSENO.

      /*  axios.post('/novarezervacija', {
            dolazakDatum: dolazakDatum,
            odlazakDatum: odlazakDatum,
            cijena: finalCijena,
            imekupca:imekupca,
            prezimekupca:prezimekupca,
            emailkupca:emailkupca,
            drzavakupca:drzavakupca,
            napomena:napomenakupca,
            promokod:promocode,
            sobe:finalRooms,
            odrasli: odraslinumber,
            djeca : djecanumber
        })
        .then(res => res.data)
        .then(data => {
            console.log(data);
            window.location.replace("/rezervacija/"+data);

        })
      */

   }


   function paymentSuccess() {
    let data = {
        dolazakDatum: dolazakDatum,
        odlazakDatum: odlazakDatum,
        cijena: finalCijena,
        imekupca:imekupca,
        prezimekupca:prezimekupca,
        emailkupca:emailkupca,
        phonekupca:phonekupac,
        drzavakupca:drzavakupca,
        napomena:napomenakupca,
        promokod:promocode,
        sobe:finalRooms,
        odrasli: odraslinumber,
    };
    return data;

   }

   function promjenaBrojOsoba(event) {
    setSobeBrojOsoba(new Map(sobeBrojOsoba.set(event.target.name,event.target.value)))
    for(let i = 0 ; i< sobe.length; i++) {
        if(sobe[i].sobaId == parseInt(event.target.name)){
            if(event.target.value == 0) {
                setSobeCijene( new Map(sobeCijene.set(event.target.name,0)))
            }else if(event.target.value == 1) {
                setSobeCijene( new Map(sobeCijene.set(event.target.name,sobe[i].cijene.jedna)))
            }else if(event.target.value == 2) {
                setSobeCijene( new Map(sobeCijene.set(event.target.name,sobe[i].cijene.dvije)))
            }else if(event.target.value == 3) {
                setSobeCijene( new Map(sobeCijene.set(event.target.name,sobe[i].cijene.tri)))
            }else if(event.target.value == 4) {
                setSobeCijene( new Map(sobeCijene.set(event.target.name,sobe[i].cijene.cetri)))
            }
        }
    }
    setClientSecret("")
   }
 

    return( <body id="rezervacija">
        <Header language={props.language} handleSetLanguage={props.handleSetLanguage}/>
        <br/>        
        <br/>
        <br/>
        <br/><br/><br/><br/>
        
        <div className='flex-container-special' >
            <div className='background'></div>
            <div className='inputData'>
                
                <h1 className='podnaslov'>{content.labelDatum}</h1>
                <br/>
                <label className='labelInput'>{content.labelDolazak} : </label>
                <br/>
                <input type={'date'} value={dolazakDatum} onChange={dolazakChanged}></input>
                <br/>                
                <br/>
                <label className='labelInput'>{content.labelOdlazak} : </label>
                <br/>
                <input type={'date'} value={odlazakDatum} onChange={odlazakChanged}></input>
                <br/><br/>
                <div className='error'>{error}</div>
                <br/>
                <div>
                <Button id='dostupnostbtn'  onClick={provjeriDostupnost}>{content.buttonDostupnost}</Button>
                <img id="load" src={loading}></img>
                </div>
                <br/>
            </div>

            <div className='background'></div>
        </div>
        <br/><br/>
        <h4>{content.popust}</h4>
        <h3>{content.bathtubincluded}</h3>
        <div id='sobezaodabir' className=''>
            {sobe.map(soba => <div key={soba.sobaId} className='Prikaz border'>
                                    {soba.sobaId == "1136581" && <img className='slika' src={supbath}></img> }
                                    {soba.sobaId == "1136584" && <img className='slika' src={zelenasup}></img> }
                                    {soba.sobaId == "1136587" && <img className='slika' src={lika5}></img> }
                                    {soba.sobaId == "1136590" && <img className='slika' src={okerkada}></img> }
                                    {soba.sobaId == "1136593" && <img className='slika' src={sivasoba}></img> }
                                    {soba.sobaId == "1136596" && <img className='slika' src={pinksoba}></img> }

                                    <div className='flex-column'>
                                        <div>
                                            <h2 className='flex-cntrText'>{content.simbolicname}{soba.simbolicnoIme}</h2>
                                            <div> {soba.nazivSoba}  </div>
                                            <span>{content.ukljucendorucak}</span>
                                        </div>
                                        <div>
                                            <span>{content.kreveticnaplata}</span><br/>
                                            <span className='textlg'>{content.odaberitebrojljudi} : </span>
                                            <select id={'form' + soba.sobaId} name={soba.sobaId}  onChange={promjenaBrojOsoba} className='biggerselect'>
                                               <option value={0}>0</option> 
                                               { soba.maxOdraslih >= 1 &&
                                                <option value={1}>1</option>
                                               }
                                               { soba.maxOdraslih >= 2 &&
                                                <option value={2}>2</option>
                                               }
                                               { soba.maxOdraslih >= 3 &&
                                                <option value={3}>3</option>
                                               }
                                               { soba.maxOdraslih >= 4 &&
                                                <option value={4}>4</option>
                                               }
                                            </select>
                                            
                                        </div>
                                        <br/>
                                        { sobeBrojOsoba.has((soba.sobaId.toString())) &&
                                       <div className='textlg'>
                                           {content.cijena} : {sobeCijene.get((soba.sobaId.toString()))} €
                                           <div className='textXXlg'>
                                            After promocode : {(sobeCijene.get((soba.sobaId.toString()))*0.9).toFixed(2)} €
                                            </div>
                                        </div> }
                                        <div>
                                               <Button onClick={() => potvrdi()} className='margin5'>{content.finishorder}</Button>
                                        </div>
                                    </div>
                            </div>    
                            )
            }
        </div>
        <h1 className='soldout'>{soldout}</h1>

        <Rooms  language={props.language} />


        <div id='potvrdeno'>
           
            <br/>
            <br/>
            <h1 style={{"text-decoration":'underline'}}>{content.labelUnosDodatni}</h1>
            <br/>
            <br/>
            <div className='backgroundcolorDataCentered'>
                <h2>{content.sažetakRezervacije} : </h2>
                <br/>
                <p><b>{content.labelDolazak} : </b> &nbsp; {dolazakDatum}</p>
                <p><b>{content.labelOdlazak} : </b> &nbsp; {odlazakDatum}</p>
                <p><b>{content.brojOdraslih} : </b> &nbsp; {odraslinumber} <i class="bi bi-person-fill"></i></p>

                <p>
                <br/>
                <h3> {content.paketSoba} :</h3>
                <br/>

                {
                    finalRooms.map(final=> <span>

                        <b>
                            1x 
                            &nbsp;
                            {final.nazivSoba}
                            &nbsp;
                        </b>
                        : &nbsp; <br/>
                        1 x {content.bracniKrevet} <br/>
                        {final.maxOdraslih - 2 } x {content.singlebed}
                        <br/>

                                    
                        </span>)
                }
                <br/>
                <hr/>
                 <b className='cijena'>
                           {content.cijena} : {finalCijena} €
                 </b>
                <br/>
                <span>{promocodeporuka}</span>


                 <div className='inputdodatak'>
                        <div  class="input-group mb-3 mt-3">
                            <span class="input-group-text" id="basic-addon1">{content.Promocode} :</span>
                            <input type="text" required class="form-control" onChange={promocodeChanged} value={promocode} aria-label="Promocode" aria-describedby="basic-addon1" />
                            <Button disabled={promocodeapplied} onClick={() => primjeniPromocode()}>{content.primjeni}</Button>
                        </div>
                    </div>


                </p>

                
            </div>
            <br/>
            <br/>
            <div className='flex-container'>
                <div></div>
                <form className='borderinput'>

                    <div className='inputdodatak'>
                        <div  class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon1">*{content.ime}</span>
                            <input type="text" required class="form-control" onChange={imeChanged} value={imekupca} aria-label="Name" aria-describedby="basic-addon1" />
                        </div>
                    </div>
                    <div className='inputdodatak'>
                        <div  class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon1">*{content.prezime}</span>
                            <input type="text" required class="form-control" onChange={prezimeChanged} value={prezimekupca} aria-label="Name" aria-describedby="basic-addon1" />
                        </div>
                    </div>
                    <div className='inputdodatak'>
                        <div  class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon1">*Email:</span>
                            <input type="email" required class="form-control" onChange={emailChanged} value={emailkupca} aria-label="Name" aria-describedby="basic-addon1" />
                        </div>
                    </div>
                    <div className='inputdodatak'>
                        <div>Phone number must be in the form of : +(country code) (phone number) eg. +385 998133790</div>
                        <div  class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon1">*Phone:</span>
                            <input type="email" required class="form-control" onChange={phoneChanged} value={phonekupac} aria-label="Name" aria-describedby="basic-addon1" />
                        </div>
                    </div>

                    <div className='inputdodatak'>
                        <div  class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon1">*{content.država}</span>
                            <select required  onChange={drzavaChanged} value={drzavakupca}  >

                                <option value="AFG">Afghanistan</option>
                                <option value="ALA">Åland Islands</option>
                                <option value="ALB">Albania</option>
                                <option value="DZA">Algeria</option>
                                <option value="ASM">American Samoa</option>
                                <option value="AND">Andorra</option>
                                <option value="AGO">Angola</option>
                                <option value="AIA">Anguilla</option>
                                <option value="ATA">Antarctica</option>
                                <option value="ATG">Antigua and Barbuda</option>
                                <option value="ARG">Argentina</option>
                                <option value="ARM">Armenia</option>
                                <option value="ABW">Aruba</option>
                                <option value="AUS">Australia</option>
                                <option value="AUT">Austria</option>
                                <option value="AZE">Azerbaijan</option>
                                <option value="BHS">Bahamas</option>
                                <option value="BHR">Bahrain</option>
                                <option value="BGD">Bangladesh</option>
                                <option value="BRB">Barbados</option>
                                <option value="BLR">Belarus</option>
                                <option value="BEL">Belgium</option>
                                <option value="BLZ">Belize</option>
                                <option value="BEN">Benin</option>
                                <option value="BMU">Bermuda</option>
                                <option value="BTN">Bhutan</option>
                                <option value="BOL">Bolivia, Plurinational State of</option>
                                <option value="BES">Bonaire, Sint Eustatius and Saba</option>
                                <option value="BIH">Bosnia and Herzegovina</option>
                                <option value="BWA">Botswana</option>
                                <option value="BVT">Bouvet Island</option>
                                <option value="BRA">Brazil</option>
                                <option value="IOT">British Indian Ocean Territory</option>
                                <option value="BRN">Brunei Darussalam</option>
                                <option value="BGR">Bulgaria</option>
                                <option value="BFA">Burkina Faso</option>
                                <option value="BDI">Burundi</option>
                                <option value="KHM">Cambodia</option>
                                <option value="CMR">Cameroon</option>
                                <option value="CAN">Canada</option>
                                <option value="CPV">Cape Verde</option>
                                <option value="CYM">Cayman Islands</option>
                                <option value="CAF">Central African Republic</option>
                                <option value="TCD">Chad</option>
                                <option value="CHL">Chile</option>
                                <option value="CHN">China</option>
                                <option value="CXR">Christmas Island</option>
                                <option value="CCK">Cocos (Keeling) Islands</option>
                                <option value="COL">Colombia</option>
                                <option value="COM">Comoros</option>
                                <option value="COG">Congo</option>
                                <option value="COD">Congo, the Democratic Republic of the</option>
                                <option value="COK">Cook Islands</option>
                                <option value="CRI">Costa Rica</option>
                                <option value="CIV">Côte d'Ivoire</option>
                                <option value="HRV">Croatia</option>
                                <option value="CUB">Cuba</option>
                                <option value="CUW">Curaçao</option>
                                <option value="CYP">Cyprus</option>
                                <option value="CZE">Czech Republic</option>
                                <option value="DNK">Denmark</option>
                                <option value="DJI">Djibouti</option>
                                <option value="DMA">Dominica</option>
                                <option value="DOM">Dominican Republic</option>
                                <option value="ECU">Ecuador</option>
                                <option value="EGY">Egypt</option>
                                <option value="SLV">El Salvador</option>
                                <option value="GNQ">Equatorial Guinea</option>
                                <option value="ERI">Eritrea</option>
                                <option value="EST">Estonia</option>
                                <option value="ETH">Ethiopia</option>
                                <option value="FLK">Falkland Islands (Malvinas)</option>
                                <option value="FRO">Faroe Islands</option>
                                <option value="FJI">Fiji</option>
                                <option value="FIN">Finland</option>
                                <option value="FRA">France</option>
                                <option value="GUF">French Guiana</option>
                                <option value="PYF">French Polynesia</option>
                                <option value="ATF">French Southern Territories</option>
                                <option value="GAB">Gabon</option>
                                <option value="GMB">Gambia</option>
                                <option value="GEO">Georgia</option>
                                <option value="DEU">Germany</option>
                                <option value="GHA">Ghana</option>
                                <option value="GIB">Gibraltar</option>
                                <option value="GRC">Greece</option>
                                <option value="GRL">Greenland</option>
                                <option value="GRD">Grenada</option>
                                <option value="GLP">Guadeloupe</option>
                                <option value="GUM">Guam</option>
                                <option value="GTM">Guatemala</option>
                                <option value="GGY">Guernsey</option>
                                <option value="GIN">Guinea</option>
                                <option value="GNB">Guinea-Bissau</option>
                                <option value="GUY">Guyana</option>
                                <option value="HTI">Haiti</option>
                                <option value="HMD">Heard Island and McDonald Islands</option>
                                <option value="VAT">Holy See (Vatican City State)</option>
                                <option value="HND">Honduras</option>
                                <option value="HKG">Hong Kong</option>
                                <option value="HUN">Hungary</option>
                                <option value="ISL">Iceland</option>
                                <option value="IND">India</option>
                                <option value="IDN">Indonesia</option>
                                <option value="IRN">Iran, Islamic Republic of</option>
                                <option value="IRQ">Iraq</option>
                                <option value="IRL">Ireland</option>
                                <option value="IMN">Isle of Man</option>
                                <option value="ISR">Israel</option>
                                <option value="ITA">Italy</option>
                                <option value="JAM">Jamaica</option>
                                <option value="JPN">Japan</option>
                                <option value="JEY">Jersey</option>
                                <option value="JOR">Jordan</option>
                                <option value="KAZ">Kazakhstan</option>
                                <option value="KEN">Kenya</option>
                                <option value="KIR">Kiribati</option>
                                <option value="PRK">Korea, Democratic People's Republic of</option>
                                <option value="KOR">Korea, Republic of</option>
                                <option value="KWT">Kuwait</option>
                                <option value="KGZ">Kyrgyzstan</option>
                                <option value="LAO">Lao People's Democratic Republic</option>
                                <option value="LVA">Latvia</option>
                                <option value="LBN">Lebanon</option>
                                <option value="LSO">Lesotho</option>
                                <option value="LBR">Liberia</option>
                                <option value="LBY">Libya</option>
                                <option value="LIE">Liechtenstein</option>
                                <option value="LTU">Lithuania</option>
                                <option value="LUX">Luxembourg</option>
                                <option value="MAC">Macao</option>
                                <option value="MKD">Macedonia, the former Yugoslav Republic of</option>
                                <option value="MDG">Madagascar</option>
                                <option value="MWI">Malawi</option>
                                <option value="MYS">Malaysia</option>
                                <option value="MDV">Maldives</option>
                                <option value="MLI">Mali</option>
                                <option value="MLT">Malta</option>
                                <option value="MHL">Marshall Islands</option>
                                <option value="MTQ">Martinique</option>
                                <option value="MRT">Mauritania</option>
                                <option value="MUS">Mauritius</option>
                                <option value="MYT">Mayotte</option>
                                <option value="MEX">Mexico</option>
                                <option value="FSM">Micronesia, Federated States of</option>
                                <option value="MDA">Moldova, Republic of</option>
                                <option value="MCO">Monaco</option>
                                <option value="MNG">Mongolia</option>
                                <option value="MNE">Montenegro</option>
                                <option value="MSR">Montserrat</option>
                                <option value="MAR">Morocco</option>
                                <option value="MOZ">Mozambique</option>
                                <option value="MMR">Myanmar</option>
                                <option value="NAM">Namibia</option>
                                <option value="NRU">Nauru</option>
                                <option value="NPL">Nepal</option>
                                <option value="NLD">Netherlands</option>
                                <option value="NCL">New Caledonia</option>
                                <option value="NZL">New Zealand</option>
                                <option value="NIC">Nicaragua</option>
                                <option value="NER">Niger</option>
                                <option value="NGA">Nigeria</option>
                                <option value="NIU">Niue</option>
                                <option value="NFK">Norfolk Island</option>
                                <option value="MNP">Northern Mariana Islands</option>
                                <option value="NOR">Norway</option>
                                <option value="OMN">Oman</option>
                                <option value="PAK">Pakistan</option>
                                <option value="PLW">Palau</option>
                                <option value="PSE">Palestinian Territory, Occupied</option>
                                <option value="PAN">Panama</option>
                                <option value="PNG">Papua New Guinea</option>
                                <option value="PRY">Paraguay</option>
                                <option value="PER">Peru</option>
                                <option value="PHL">Philippines</option>
                                <option value="PCN">Pitcairn</option>
                                <option value="POL">Poland</option>
                                <option value="PRT">Portugal</option>
                                <option value="PRI">Puerto Rico</option>
                                <option value="QAT">Qatar</option>
                                <option value="REU">Réunion</option>
                                <option value="ROU">Romania</option>
                                <option value="RUS">Russian Federation</option>
                                <option value="RWA">Rwanda</option>
                                <option value="BLM">Saint Barthélemy</option>
                                <option value="SHN">Saint Helena, Ascension and Tristan da Cunha</option>
                                <option value="KNA">Saint Kitts and Nevis</option>
                                <option value="LCA">Saint Lucia</option>
                                <option value="MAF">Saint Martin (French part)</option>
                                <option value="SPM">Saint Pierre and Miquelon</option>
                                <option value="VCT">Saint Vincent and the Grenadines</option>
                                <option value="WSM">Samoa</option>
                                <option value="SMR">San Marino</option>
                                <option value="STP">Sao Tome and Principe</option>
                                <option value="SAU">Saudi Arabia</option>
                                <option value="SEN">Senegal</option>
                                <option value="SRB">Serbia</option>
                                <option value="SYC">Seychelles</option>
                                <option value="SLE">Sierra Leone</option>
                                <option value="SGP">Singapore</option>
                                <option value="SXM">Sint Maarten (Dutch part)</option>
                                <option value="SVK">Slovakia</option>
                                <option value="SVN">Slovenia</option>
                                <option value="SLB">Solomon Islands</option>
                                <option value="SOM">Somalia</option>
                                <option value="ZAF">South Africa</option>
                                <option value="SGS">South Georgia and the South Sandwich Islands</option>
                                <option value="SSD">South Sudan</option>
                                <option value="ESP">Spain</option>
                                <option value="LKA">Sri Lanka</option>
                                <option value="SDN">Sudan</option>
                                <option value="SUR">Suriname</option>
                                <option value="SJM">Svalbard and Jan Mayen</option>
                                <option value="SWZ">Swaziland</option>
                                <option value="SWE">Sweden</option>
                                <option value="CHE">Switzerland</option>
                                <option value="SYR">Syrian Arab Republic</option>
                                <option value="TWN">Taiwan, Province of China</option>
                                <option value="TJK">Tajikistan</option>
                                <option value="TZA">Tanzania, United Republic of</option>
                                <option value="THA">Thailand</option>
                                <option value="TLS">Timor-Leste</option>
                                <option value="TGO">Togo</option>
                                <option value="TKL">Tokelau</option>
                                <option value="TON">Tonga</option>
                                <option value="TTO">Trinidad and Tobago</option>
                                <option value="TUN">Tunisia</option>
                                <option value="TUR">Turkey</option>
                                <option value="TKM">Turkmenistan</option>
                                <option value="TCA">Turks and Caicos Islands</option>
                                <option value="TUV">Tuvalu</option>
                                <option value="UGA">Uganda</option>
                                <option value="UKR">Ukraine</option>
                                <option value="ARE">United Arab Emirates</option>
                                <option value="GBR">United Kingdom</option>
                                <option value="USA">United States</option>
                                <option value="UMI">United States Minor Outlying Islands</option>
                                <option value="URY">Uruguay</option>
                                <option value="UZB">Uzbekistan</option>
                                <option value="VUT">Vanuatu</option>
                                <option value="VEN">Venezuela, Bolivarian Republic of</option>
                                <option value="VNM">Viet Nam</option>
                                <option value="VGB">Virgin Islands, British</option>
                                <option value="VIR">Virgin Islands, U.S.</option>
                                <option value="WLF">Wallis and Futuna</option>
                                <option value="ESH">Western Sahara</option>
                                <option value="YEM">Yemen</option>
                                <option value="ZMB">Zambia</option>
                                <option value="ZWE">Zimbabwe</option>
                            </select>
                        </div>
                    </div>
                    <div className='inputdodatak'>
                        <div  class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon1">{content.napomena}</span>
                            <input type="text"  class="form-control" onChange={napomenaChanged} value={napomenakupca} aria-label="Name" aria-describedby="basic-addon1" />
                        </div>
                    </div>
                    <div>
                        <label className="textlg">By continuing you agree with the <a rel='noreferrer' target='_blank' href="https://www.pearlofplitvice.com/terms">terms and conditions</a> &nbsp;</label>
                        <input type='checkbox'/>
                    </div>
                    <div className='error'>{errordodatnipodaci}</div>
                    <br/>
                    <Button type="button" onClick={() => {nastaviPlacanje()}} class="btn btn-primary btn-lg">{content.buttonplacanje}</Button>
                </form>
           
                <div></div>
            </div>
            <br/>
            <br/>
            <br/>
            
            <h1 id='labelpay'>{content.labelpay} {finalCijena}€</h1>
            <div className="pay">
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm payment={paymentSuccess}/>
                </Elements>
            )}
            </div> 

        </div>

        <Footer  language={props.language} handleSetLanguage={props.handleSetLanguage}/>
    </body>)
}

export default Rezerviraj;
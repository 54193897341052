import React from "react";
import axios from 'axios';
import AuthHandler from "./AuthHandler";
import IntranetHeader from "./intranetHeader";
import Mainpageintranet from "./intranetmainpage";

function admin() {



    return <div>
        <IntranetHeader />
        <br/><br/><br/><br/><br/><br/><br/><br/>   
        <Mainpageintranet/>
    </div>
}

export default admin